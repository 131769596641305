import { Box, Button, Typography, TextField, FormHelperText } from '@mui/material';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useScriptRef from '../../hooks/useScriptRef';

const SuccessBox: React.FC = () => (
    <Box sx={{ textAlign: 'center' }}>
        <Typography variant={'h6'} color={'primary'}>
            SUCCESS
        </Typography>
        <Typography>Your password has been updated —</Typography>
        <Typography>You're all set!</Typography>
        <Box sx={{ color: 'primary.contrastText' }}>
            <Button
                fullWidth
                variant={'contained'}
                component={Link}
                to={'/profile'}
                replace
                sx={{ mt: 2, minWidth: 100 }}
            >
                Back to Profile
            </Button>
        </Box>
    </Box>
);

export const ChangePasswordPage: React.FC = (props) => {
    const scriptedRef = useScriptRef();
    const { firebaseUpdatePassword } = useAuth();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                maxWidth: 1200,
                minHeight: '100vh',
                m: '0 auto',
                p: { xs: 2, sm: 8 },
            }}
        >
            <Box sx={{ width: '100%', maxWidth: 600, m: { xs: 2, sm: 8 } }}>
                <Formik
                    initialValues={{
                        oldPassword: '',
                        newPassword: '',
                        submit: null,
                    }}
                    validationSchema={Yup.object().shape({
                        oldPassword: Yup.string().max(255).required('Required'),
                        newPassword: Yup.string().min(6).max(255).required('Required'),
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            await firebaseUpdatePassword(values.oldPassword, values.newPassword);
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                        } catch (err: any) {
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({
                                    submit: err.code === 'auth/wrong-password' ? 'Wrong Password' : err.message,
                                });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, status }) => {
                        return status?.success ? (
                            <SuccessBox />
                        ) : (
                            <form noValidate onSubmit={handleSubmit}>
                                <Typography variant={'h4'} sx={{ textAlign: 'center' }}>
                                    Change Password
                                </Typography>
                                {/* <Typography sx={{ mt: 1, mb: { xs: 2, sm: 8 }, textAlign: 'center' }}>
                                        Enter your new password below.
                                    </Typography> */}
                                <TextField
                                    id={'old-password'}
                                    name={'oldPassword'}
                                    type={'password'}
                                    label={'Old Password'}
                                    variant={'outlined'}
                                    fullWidth
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    sx={{ mt: { xs: 2, sm: 8 } }}
                                    error={touched.oldPassword && Boolean(errors.oldPassword)}
                                    helperText={touched.oldPassword && errors.oldPassword}
                                />
                                <TextField
                                    id={'new-password'}
                                    name={'newPassword'}
                                    type={'password'}
                                    label={'New Password'}
                                    variant={'outlined'}
                                    fullWidth
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    sx={{ mt: 2 }}
                                    error={touched.newPassword && Boolean(errors.newPassword)}
                                    helperText={touched.newPassword && errors.newPassword}
                                />
                                {errors.submit && (
                                    <Box sx={{ mt: 2 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}
                                <Button
                                    fullWidth
                                    type={'submit'}
                                    variant={'contained'}
                                    color={'primary'}
                                    sx={{ mt: 2 }}
                                    disabled={
                                        isSubmitting ||
                                        Boolean(errors.oldPassword) ||
                                        values.oldPassword.length < 6 ||
                                        Boolean(errors.newPassword) ||
                                        values.newPassword.length < 6
                                    }
                                >
                                    Change Password
                                </Button>
                            </form>
                        );
                    }}
                </Formik>
                {/* )} */}
            </Box>
        </Box>
    );
};
