import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import montage from '../../../assets/images/news/montage.jpeg';
import arbor from '../../../assets/images/news/arbor-archway.jpeg';
import tech from '../../../assets/images/news/irrigation-tech.jpeg';

export const LastingPartnershipArticle: React.FC = () => (
    <>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>A Lasting Partnership: Customer Highlight</Typography>
            <img
                src={montage}
                style={{ width: '100%', height: 'auto', marginTop: 16 }}
                alt={'A view of the Montage monument'}
            />
            <Typography
                component={'blockquote'}
                sx={{
                    m: 4,
                    ml: 'auto',
                    mr: 'auto',
                    maxWidth: 600,
                    pl: 2,
                    borderLeft: '8px solid white',
                    borderLeftColor: 'primary.main',
                }}
            >
                {`"There are some vendors who are just vendors, but every once in a while you come across a company that you can be a partner with and that makes a big difference. With Serpico, it's a partnership."`}
                <b>{` —  Montage Master Association Landscape Committee Chairperson Dennis Kolb`}</b>
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`As a homeowner and Landscape Committee member, Dennis Kolb has worked with five different landscape companies in three different states. He has experienced the challenges of typical landscape service for many years, so he recognized excellence when he was introduced to Serpico.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Over-planted and under-planned</Typography>
            <Typography sx={{ mt: 2 }}>
                {`When Kolb bought his house at Montage in 2014, the common area landscape was heavily over-planted, with trees too close to buildings and crowded beds competing for water and nutrients - a common issue in newly built communities. A landscape plan that is designed to look good for sales often is not sustainable long-term. Unlike most developments, Montage Master Association has two different styles of condominiums as well as various private homes. This arrangement makes the landscape very complex because of the size and spacing of homes.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`A beautiful, well maintained landscape is essential to property value in developments like Montage, where it is the first thing you see when you enter the community. “Homeowners and prospective buyers want to see green, flowers,” Kolb notes. “If the landscape is well maintained, you have to assume everything else is and the HOA board must care about the community.”`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`The landscape contractor at the time had been hired by the developer and only performed minimal maintenance on the property. In their typical “mow and blow” contract, they weren't focused on plant health, longevity or efficient water management, and they could not provide any extra services like enhancements or tree care.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>A choice for a sustainable future</Typography>
            <Typography sx={{ mt: 2 }}>
                {`In 2015, the Association sought a new landscape contractor they could trust to care for their plants and their residents. Kolb knew that Serpico had a good reputation as a full service company and he was impressed that their officers attended the bid presentation, not just their local team. Serpico's thorough plan demonstrated their commitment to Montage and earned them the contract.`}
            </Typography>
            <img
                src={arbor}
                style={{ width: '100%', height: 'auto', marginTop: 16 }}
                alt={'Looking through a lush archway tunnel'}
            />
            <Typography variant={'caption'} component={'figcaption'} sx={{ mt: 2 }}>
                {`A lush archway protects mailbox kiosks and creates a magical view of a walkaway between homes.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Strong plants, strong partnerships</Typography>
            <Typography sx={{ mt: 2 }}>
                {`In the early days of the contract, Serpico's arborist met with the HOA board to walk the property and decide which trees could be removed to improve their health. They worked diligently to thin out plant material and reduce the number of trees from around 3,000 to 1,500. The trees that are left now are flourishing. Kolb notes the community's landscape budget would have been twice what they're spending now, to maintain all the extra trees. Serpico has re-inventoried all of the trees in 2021 and has shared a spreadsheet with the Landscape Committee, to assist in tracking their health and trimming requirements.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`In addition to the `}
                <Link to={'/services/tree-care'}>Tree Care</Link>
                {` and `}
                <Link to={'/services/landscape-enhancements'}>Landscape Enhancement</Link>
                {` services they provide, Serpico's monthly maintenance contract includes turf, entrance monuments, bioswales and hundreds of different kinds of plants, all using recycled water.`}
            </Typography>
            <img
                src={tech}
                style={{ width: '100%', height: 'auto', marginTop: 16 }}
                alt={'An irrigation tech inspecting a sprinkler system'}
            />
            <Typography variant={'caption'} component={'figcaption'} sx={{ mt: 2 }}>
                {`An irrigation technician performs regular maintenance on a sprinkler head in the main lawn.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Northern California irrigation expertise</Typography>
            <Typography sx={{ mt: 2 }}>
                {`To keep water usage to a minimum while keeping plants in peak health, Serpico installed 10 `}
                <a href={'https://www.hydropoint.com/weathertrak/products/weathertrak-optiflow-xr/'}>
                    WeatherTRAK Optiflow XR
                </a>
                {` controllers to upgrade Montage's irrigation system. The `}
                <a href={'https://www.epa.gov/watersense'}>EPA WaterSense</a>
                {` labeled flow management controllers are manufactured by Petaluma, CA-based HydroPoint Data Systems. Serpico's irrigation experts are factory trained to install, troubleshoot and manage the high tech system. Before installation, a HydroPoint representative came to Montage to answer questions to make sure it was the best fit.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Kolb says in previous years if the property had an irrigation break on a Saturday afternoon it would run and run until someone reported it, then they'd have to call their landscape company out on an emergency basis, which cost extra money. Now, with Serpico managing the Optiflow system, a sensor recognizes a break, alerts Serpico, and Serpico can shut off the valve remotely. This speedy response not only prevents water waste, it also mitigates damage to the surrounding landscape and hardscape from excessive water. The irrigation manager is also able to manage daily functions of the controller remotely.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Water conservation is important to the HOA board and the Landscape Committee but a sparse or xeriscaped aesthetic does not fit their community, so Serpico's unique expertise was exactly what Montage needed. Beyond the irrigation crew's technical training, the landscape crew utilizes horticulture practices that support each plant's natural beauty and strength without relying on heavy watering.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Professional in every way</Typography>
            <Typography sx={{ mt: 2 }}>
                {`Montage neighbors regularly point out that the Serpico crew works very hard and is very respectful of the community. “The people who are here everyday are the real faces of Serpico. We have a professional and respectful crew,” Kolb notes. The dedicated crew is on the Montage property every weekday, working in five rotation zones. “They stop what they're doing to let people go by and they wave to homeowners. They're sensitive to the fact that they're on a property that doesn't belong to them but they take care of it.”  `}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`The management team elevates the professionalism even further, attending monthly landscape meetings with the Montage committee, providing monthly reports and staying in touch on a regular basis to communicate what's happening on the property and in the landscape industry. Kolb is impressed that the senior management team has not lost focus on his property after winning the contract, but they continue to keep him up-to-date on business initiatives and other relevant information.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Partnership benefits everyone</Typography>
            <Typography sx={{ mt: 2 }}>
                {`The Montage HOA board usually reevaluates service contracts every year. Because of the excellent level of commitment and service Serpico has demonstrated at Montage, the board worked out legal language in the landscape contract so they don't have to reevaluate for two to three years. Kolb says this arrangement benefits both parties. He adds, “If you have to look at the terms of a contract, there's something wrong. You should only look at it when you renew and never again. Because we're partners, we've figured out how we both can win.”`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`If you are looking for a long-term partnership for your landscape maintenance, `}
                <Link to={'/request-proposal'}>Request a Proposal</Link>
                {` and we will be in touch!`}
            </Typography>
        </Box>
    </>
);
