import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { GuardProps } from '../../types';

const GuestGuard = ({ children }: GuardProps) => {
    const { isLoggedIn } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            navigate((location.state as { from: any })?.from ? (location.state as { from: any }).from : '/', {
                replace: true,
            });
        }
    }, [isLoggedIn, navigate, location.state]);

    return children;
};

export default GuestGuard;
