import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import bush from '../../../assets/images/news/bush.jpeg';

export const MindAndBodyArticle: React.FC = () => (
    <>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Landscapes Are Healthy for Mind and Body</Typography>
            <Box sx={{ width: '100%', mt: 2, pb: '56.25%', position: 'relative' }}>
                <img
                    src={bush}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                    alt={'A large flower garden growing atop a trellis.'}
                />
            </Box>
            <Typography sx={{ mt: 2 }}>
                {`Scottish-American naturalist John Muir, who often is referred to as the “father” of America's national parks, once said “In every walk with Nature one receives far more than he seeks.”`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`We're thinking about Muir and the benefits of time spent in outdoor greenery, because the long-term impacts of 2020 and the pandemic are inescapable. It's time to get more nature in your life, because science supports what Muir said: Being surrounded by trees, shrubs, perennials and annual flowering plants, turf grasses, and ground covers makes everyone healthier. A beautiful landscape gives us more than we might imagine.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Nature as a natural pharmaceutical</Typography>
            <Typography sx={{ mt: 2 }}>
                {`Dirty air is a bane of urban areas. The American Landscape Association reports that in 2016 the American Lung Association declared Louisville, Kentucky, as having some of the worst pollution in the nation causing “high levels” of asthma and heart disease.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`To combat the situation and scientifically test the hypothesis that trees improve health in polluted environments, the University of Louisville Medical Center in late 2017 began its five-year Green Heart research project involving a clinical trial of the impact trees have on human health.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Green Heart is a joint project with the `}
                <a href={'https://www.nature.org/en-us/'}>Nature Conservancy</a>
                {` and `}
                <a href={'https://www.hyphae.net/'}>Hyphae Design Laboratory</a>
                {` of Oakland, California. Hyphae is an engineering firm that focuses on creating a healthy intersection between the built environment and nature.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`According to the medical center's lead researcher Dr. Aruni Bhatnagar, the study aims to quantify the “health effects of urban greening” and whether the strategic planting of natural elements, such as trees, can be a “replicable therapy”.`}
            </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Greener neighborhoods, better pregnancy and births</Typography>
            <Typography sx={{ mt: 2 }}>
                {`Researchers at Oregon State University (OSU) and the University of British Columbia in 2014 published a study showing that green environments are healthier for pregnancy.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`OSU noted that the study involved more than 64,000 births and found that pre-term births were lower among mothers living in “greener” neighborhoods. It also pointed to better birth weight for babies from green neighborhoods.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Here is what particularly surprised the researchers: According to OSU, after the researchers set aside key factors of neighborhood income, pollution and walkability, they discovered that “greenness” affects birth outcomes in other ways, such as psychologically or socially.`}
            </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Healthy view from a window</Typography>
            <Typography sx={{ mt: 2 }}>
                <a href={'https://lsa.umich.edu/psych/people/emeriti-faculty/rkaplan.html'}>Rachel Kaplan</a>
                {`, University of Michigan professor emerita of natural resources and psychology, spent her career studying the impact of the natural environment on people. One of her conclusions is that nature “does not need to be remote and pristine” to have a positive impact. In particular, Kaplan notes that even viewing nature from a window is restorative and decreases stress.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`In 2001, `}
                <a href={'https://www.apa.org/monitor/apr01/greengood'}>an article</a>
                {` in the American Psychological Association's Monitor on Psychology magazine reported on Kaplan's research posing “important implications for how we build our homes, work environments and cities.”`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`The article noted that one of Kaplan's insights into the beneficial impact of nature in urban environments was personal. After moving from an office that looked out on a brick wall and a tree stump, Kaplan felt that the view from her new office – from which she saw trees, birds and squirrels – restored her ability to think clearly and get work done.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Similarly, in 2016, researchers at the College of Fine and Applied Arts in Champaign-Urbana, Illinois observed that high school students in testing situations did better in rooms with windows “opening onto green spaces.”`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`The college reported that compared to students in rooms without windows or with views of buildings, students who could take brief breaks to look out on greenery recovered faster from test anxiety and “had a 13 percent increase in their ability to concentrate.”`}
            </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>The view from Serpico's Landscapes</Typography>
            <Typography sx={{ mt: 2 }}>
                {`At Serpico, we experience the feeling of restoration daily during our work for HOAs, corporations, and other clients seeking services including:`}
            </Typography>
            <Typography component={'li'} sx={{ mt: 2, ml: 4 }}>
                <Link to={'/services/landscape-maintenance'}>Landscape Maintenance</Link>
            </Typography>
            <Typography component={'li'} sx={{ mt: 2, ml: 4 }}>
                <Link to={'/services/landscape-enhancements'}>Landscape Enhancement</Link>
            </Typography>
            <Typography component={'li'} sx={{ mt: 2, ml: 4 }}>
                <Link to={'/services'}>Planning and Design</Link>
            </Typography>
            <Typography component={'li'} sx={{ mt: 2, ml: 4 }}>
                <Link to={'/services/irrigation-management'}>Irrigation Management</Link>
            </Typography>
            <Typography component={'li'} sx={{ mt: 2, ml: 4 }}>
                <Link to={'/services/tree-care'}>Tree Care</Link>
            </Typography>

            <Typography sx={{ mt: 2 }}>
                {`Serpico is dedicated to creating healthy environments and improving communities with every landscape we touch. We specialize in landscaping services that create, maintain, and enhance the total living environment of your HOA or commercial property. `}
                <Link to={'/request-proposal'}>Request a Proposal</Link>
                {` to learn how our professional services can fit perfectly into your budget.`}
            </Typography>
        </Box>
    </>
);
