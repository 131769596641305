export const GRID_SPACING = 3;
export const DRAWER_WIDTH = 260;
export const APP_PREFIX = '@@SERPICO';
export const AUTH_TOKEN_KEY = `${APP_PREFIX}/auth-token`;

export const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyDrVkbePbmmprXKnR9_hEasvNhoBUGmMQo',
    authDomain: 'serpico-d762a.firebaseapp.com',
    projectId: 'serpico-d762a',
    storageBucket: 'serpico-d762a.appspot.com',
    messagingSenderId: '271255912103',
    appId: '1:271255912103:web:e7a3681239eb66e80e6582',
    measurementId: 'G-LMPLEW5M5G',
};

export const SOCIAL_MEDIA = {
    FACEBOOK: 'https://www.facebook.com/SerpicoLandscaping/',
    INSTAGRAM: 'https://www.instagram.com/serpicoinc/',
    LINKEDIN: 'https://www.linkedin.com/company/serpico-landscaping-inc--treeco-tree-service/',
};
