import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import boardwalk from '../../../assets/images/news/boardwalk.jpeg';
import oasis from '../../../assets/images/news/oasis.jpeg';
import pergola from '../../../assets/images/news/pergola.jpeg';

export const OasisArticle: React.FC = () => (
    <>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>A Magical Oasis for Healing</Typography>
            <Box sx={{ width: '100%', mt: 2, pb: '56.25%', position: 'relative' }}>
                <img
                    src={oasis}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                    alt={'A large flower garden growing atop a trellis.'}
                />
            </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>The leader in care and comfort</Typography>
            <Typography sx={{ mt: 2 }}>
                {`Parents call `}
                <a
                    href={
                        'https://georgemark.org/who-we-are/?gclid=CjwKCAiAiKuOBhBQEiwAId_sK_CSreh5QYhMEcZWjt7j5zC3LaqcVliA4HhSIBMn66cz_bTVEG7U5hoCnasQAvD_BwE'
                    }
                >
                    George Mark Children's House
                </a>
                {` in San Leandro “a magical place” and “a refuge”. The first freestanding pediatric palliative care center in the United States, George Mark's has been the leader and model in children's palliative care since it opened in 2004. The house provides life-affirming care and comfort for children and their families, focusing on quality of life and continuity of care for children with illnesses that modern healthcare cannot yet cure, or for those who have chronic medical conditions.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`This mission captured the heart of Serpico Landscaping Owner, Peter Novak. Understanding the immense benefits that a beautiful landscape can provide for peace and healing, Serpico provides landscaping services to the organization at no cost.`}
            </Typography>
            <img
                src={pergola}
                style={{ width: '100%', height: 'auto', marginTop: 16 }}
                alt={'A beautifully maintained lawn'}
            />
            <Typography variant={'caption'} component={'figcaption'} sx={{ mt: 2 }}>
                {`A beautifully maintained lawn at George Mark Children's House`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>High quality landscape service, no cost</Typography>
            <Typography sx={{ mt: 2 }}>
                {`Despite being a pro bono service, the crew of landscape, tree care and irrigation professionals delivers the same quality work as every other contract they serve.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`A committed crew performs weekly lawn care, landscaping, tree care and irrigation services for all eight acres of the Children's House property. The crew values getting to work at George Mark's because it's a cause they care about, and they take the work very seriously. Many employees say it is their favorite project.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Water efficiency diverts more funds to families</Typography>
            <Typography sx={{ mt: 2 }}>
                {`Because of the crew's advanced education and training on horticulture and water management practices, the Children's House not only saves money on their landscape, they benefit from lower water bills, so they can direct more funds to helping families. Serpico's proactive irrigation management means the staff does not have to worry about local water regulations or getting fines, because their crew makes sure the irrigation is always in compliance.`}
            </Typography>
            <img
                src={boardwalk}
                style={{ width: '100%', height: 'auto', marginTop: 16 }}
                alt={'A raised boardwalk traversing a well manicured landscape'}
            />
            <Typography variant={'caption'} component={'figcaption'} sx={{ mt: 2 }}>
                {`A raised boardwalk makes visiting the garden accessible for all guests at George Mark Children's House`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Bringing Hummingbird Hill to life</Typography>
            <Typography sx={{ mt: 2 }}>
                {`Recently, George Mark Children's House families came together to get an area of excess land adjacent to the property converted into a fully accessible restorative garden. The project was a collaborative effort between Serpico and other area landscape companies, with funds raised and donated to develop the garden. Because of the location on a steep hill, Serpico's volunteers hand-carried mulch in buckets up to the garden. Other volunteers had similar tasks they tackled energetically to help take Hummingbird Hill from dream to reality.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`What was previously an unused area now provides wheelchair accessibility, shaded seating, raised planting beds, a sensory garden, an outdoor kitchen, and art and water features to GMCH residents and their families. The Serpico crew that maintains the rest of the landscape has added the new garden to their weekly landscaping rotation.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`If shared values are important to your community, `}
                <Link to={'/request-proposal'}>Request a Proposal</Link>
                {` to begin your own partnership with Serpico.`}
            </Typography>
        </Box>
    </>
);
