import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const BoosterFuelsArticle: React.FC = () => (
    <>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Recapturing Time with Booster Fuels</Typography>
            <Box sx={{ width: '100%', mt: 2, pb: '56.25%', position: 'relative' }}>
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/9RIkxM4gFjM"
                    frameBorder={'0'}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0 }}
                />
            </Box>
            <Typography sx={{ mt: 2 }}>
                {`We've always been a company that has embraced innovation and technology since the very beginning. With close to 100 vehicles in our fleet, our pin card program was a mess. There was a lot of admin, with receipts, passwords and PINs. We learned about `}
                <a href={'https://boosterusa.com/how-it-works/'}>Booster Fuels</a>
                {` and thought “That looks like something new, we should look into this.”`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`We did a lot of research into where we have our biggest pockets of nonproductive time and one of the biggest pockets we found was in the refueling process. We calculated that in a month we make about 500 stops to the gas station throughout the company. On average we have 3-4 crew members in a truck spending 20 minutes of unproductive time for every stop. You can assign a dollar value to that and it's a pretty astounding number. Booster has helped us recapture that nonproductive time.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`With the additional time we've now unlocked in our day, my crews can spend more time training, more time generating revenue. We've been able to reinvest some of that lost time in quality improvement efforts. What that's done is not just raised our service levels across the board with our clients, it's also helped our retention rate.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Booster has reinvented the way we look at fueling our fleet. We're on a growth trajectory right now. The great thing about Booster joining us on this journey is that the more we grow, the more return we get. Time is money and Booster saves us time.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Read more about Booster's `}
                <a href={'https://boosterusa.com/case-study/serpico-landscaping/'}>case study</a>
                {` and check out their case study `}
                <a href={'https://youtu.be/9RIkxM4gFjM'}>video</a>
                {` on YouTube.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Learn more about our partnership with Booster Fuels in this article from `}
                <a
                    href={
                        'https://www.totallandscapecare.com/business/article/15042241/booster-brings-the-gas-station-to-landscape-fleets'
                    }
                >
                    Total Landscape Care Magazine
                </a>
                {`.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`If innovation and sustainability are your favorite words too, `}
                <Link to={'/request-proposal'}>Request a Proposal</Link>
                {` to find out how Serpico can support your `}
                <Link to={'/request-proposal?service=landscape-maintenance'}>Landscape Maintenance</Link>
                {` and `}
                <Link to={'/request-proposal?service=tre-work'}>Tree Care</Link>
                {` needs.`}
            </Typography>
        </Box>
    </>
);
