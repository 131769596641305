import { Box, Button, Typography, TextField, FormHelperText } from '@mui/material';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useScriptRef from '../../hooks/useScriptRef';

export const LoginPage: React.FC = (props) => {
    const scriptedRef = useScriptRef();
    const { firebaseEmailPasswordSignIn } = useAuth();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: 1200,
                minHeight: '100vh',
                m: '0 auto',
                p: { xs: 2, sm: 8 },
            }}
        >
            <Typography variant={'h4'} sx={{ textAlign: 'center' }}>
                Welcome back!
            </Typography>

            <Box sx={{ width: '100%', maxWidth: 600, m: { xs: 2, sm: 8 } }}>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        submit: null,
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        password: Yup.string().max(255).required('Password is required'),
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            await firebaseEmailPasswordSignIn(values.email, values.password);
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                        } catch (err: any) {
                            console.error(err);
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
                        return (
                            <form noValidate onSubmit={handleSubmit}>
                                <TextField
                                    id={'email-address'}
                                    name={'email'}
                                    label={'Email Address'}
                                    variant={'outlined'}
                                    fullWidth
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                                <TextField
                                    id={'password'}
                                    name={'password'}
                                    label={'Password'}
                                    variant={'outlined'}
                                    type={'password'}
                                    fullWidth
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.password && Boolean(errors.password)}
                                    helperText={touched.password && errors.password}
                                    sx={{ mt: 2 }}
                                />
                                {errors.submit && (
                                    <Box sx={{ mt: 2 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}
                                <Button
                                    fullWidth
                                    type={'submit'}
                                    variant={'contained'}
                                    color={'primary'}
                                    sx={{ mt: 2 }}
                                    disabled={
                                        isSubmitting ||
                                        Boolean(errors.email) ||
                                        Boolean(errors.password) ||
                                        values.password.length < 1 ||
                                        values.email.length < 1
                                    }
                                >
                                    Log In
                                </Button>
                                <Typography
                                    component={Link}
                                    to={'/forgot-password'}
                                    sx={{
                                        mt: 4,
                                        display: 'block',
                                        fontWeight: 600,
                                        textAlign: 'center',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Forgot Password?
                                </Typography>
                                <Typography sx={{ mt: 2, textAlign: 'center' }}>
                                    Need an account?{' '}
                                    <Typography
                                        component={Link}
                                        to={'/register'}
                                        sx={{ fontWeight: 600, textDecoration: 'none' }}
                                    >
                                        Sign Up
                                    </Typography>
                                </Typography>
                            </form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
};
