import { Box, Divider, Typography, SxProps, useTheme, useMediaQuery } from '@mui/material';
import { scroller } from 'react-scroll';

import technology from '../assets/images/about/technology.jpeg';
import conservation from '../assets/images/about/conservation.jpeg';
import health from '../assets/images/about/plant-health.jpeg';
import sustainability from '../assets/images/about/sustainability.jpg';

import booster from '../assets/images/suppliers/booster.png';
import greenWorks from '../assets/images/suppliers/greenworks.png';
import rainBird from '../assets/images/suppliers/rainbird.png';
import watersavers from '../assets/images/suppliers/watersavers.png';

import qwel from '../assets/images/associations/qwel.png';
import ia from '../assets/images/associations/ia-member.gif';
import rescape from '../assets/images/associations/rescape.svg';
import alameda from '../assets/images/associations/alameda.png';

import { CallToActionConfig, CallToActionGrid } from '../components/navigation';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

type CTAKey = 'technology' | 'conservation' | 'plantHealth' | 'sustainability';

const CTA_SLUGS: { [key in CTAKey]: string } = {
    technology: 'technology',
    conservation: 'resource-conservation',
    plantHealth: 'plant-health',
    sustainability: 'sustainability',
};

const transparentBorder: SxProps = {
    borderWidth: 0,
    borderBottomWidth: 8,
    borderStyle: 'solid',
    borderColor: 'transparent',
};
const selected: SxProps = {
    borderWidth: 0,
    borderBottomWidth: 8,
    borderStyle: 'solid',
    borderColor: 'primary.main',
};

const handleScrollTo = (id: string) =>
    setTimeout(
        () =>
            scroller.scrollTo(id, {
                duration: 1000,
                delay: 0,
                smooth: true,
                offset: -100,
            }),
        100
    );

export const AboutPage: React.FC = (props) => {
    const { feature: selection } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (selection && (sm || window.innerHeight < 900)) handleScrollTo(selection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPageDetails = useCallback(
        (props: { label: string; slug: string; image: string }): CallToActionConfig => {
            const { image, slug, label } = props;
            return {
                src: image,
                title: label,
                props: {
                    onClick: () => {
                        if (selection === slug) {
                            navigate('/about');
                        } else {
                            navigate(`/about/${slug}`);
                            if (sm || window.innerHeight < 900) handleScrollTo(slug);
                        }
                    },
                    sx: selection === slug ? selected : transparentBorder,
                },
            };
        },
        [selection, sm, navigate]
    );

    return (
        <Box sx={{ maxWidth: 1200, m: '0 auto', mt: { xs: 7, sm: 8 }, p: { xs: 2, sm: 8 } }}>
            <Typography variant={'h3'} sx={{ fontWeight: 500 }}>
                <Typography
                    component={'span'}
                    color={'primary.light'}
                    sx={{ font: 'inherit', display: 'inline' }}
                >
                    SERPICO
                </Typography>
                : Raising the bar for Northern California Commercial Landscaping
            </Typography>

            <Box sx={{ mt: 3 }}>
                <Typography variant={'h4'}>We take a different approach.</Typography>
                <Typography sx={{ mt: 2 }}>
                    {' '}
                    Nature makes life better. That's why we're dedicated to improving landscapes from the roots up. Our
                    people deeply understand the value of caring for plant health and conserving natural resources,
                    which is why we do things a little differently at Serpico. Learn what it means to{' '}
                    <Typography
                        component={'span'}
                        color={'primary.light'}
                        sx={{ font: 'inherit', display: 'inline', fontWeight: 600 }}
                    >
                        GoSerpico
                    </Typography>{' '}
                    below.
                </Typography>
            </Box>

            <Box sx={{ width: '100%', m: '0 auto', maxWidth: { xs: 400, sm: 'unset' } }}>
                <CallToActionGrid
                    sx={{ mt: 6 }}
                    items={[
                        getPageDetails({
                            slug: CTA_SLUGS.technology,
                            label: 'Advanced Technologies',
                            image: technology,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.conservation,
                            label: 'Resource Conservation',
                            image: conservation,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.plantHealth,
                            label: 'Plant Health',
                            image: health,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.sustainability,
                            label: 'Sustainability',
                            image: sustainability,
                        }),
                    ]}
                />
                {selection === 'technology' && (
                    <Box id={'technology'}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>Technology drives our decisions.</Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`We are not your typical landscaping company; we use technology and data to make decisions. Our technology sets us apart from the competition — it makes our work more efficient and makes our communications more effective.`}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>Artificial intelligence improves service.</Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`We use artifical intelligence in our bidding process to make sure we understand exactly what your landscape contract entails. This level of detail helps us to be more precise and helps you know what to expect. Don't settle for a landscaping company that cuts corners.`}
                            </Typography>
                        </Box>
                    </Box>
                )}
                {selection === 'resource-conservation' && (
                    <Box id={'resource-conservation'}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>Smart water management.</Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`With the right team, it is possible to have a beautiful green landscape without wasting any of our precious water resources. It takes an in-depth understanding of the landscape and precise management with the right tools, so no company is better equipped to achieve the lush, water-wise landscape of your dreams than Serpico.`}
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`Our Irrigation Specialists are ReScape California trained and certified in Bay-Friendly landscaping and design methods. We're also `}
                                <a href={'https://www.irrigation.org/clia/'}>Certified Landscape Irrigation Auditors</a>
                                {` through the `}
                                <a href={'https://www.irrigation.org/IA'}>Irrigation Association</a>
                                {` (IA) and `}
                                <a href={'https://www.qwel.net/about'}>Qualified Water Efficient Landscapers</a>
                                {` (QWEL), a west coast affiliation of the US EPA's `}
                                <a href={'https://www.epa.gov/watersense'}>WaterSense</a>
                                {` program.`}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>Smart resource management.</Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`Our resource conservation efforts go beyond `}
                                <Link to={'/services/irrigation-management'}>irrigation management</Link>
                                {`, to fuel, energy and labor. Each of our branch locations has been specifically chosen to keep our routes tight, so crews spend less time driving from home base to their properties and less time driving between properties. We are also adding battery powered equipment and hybrid trucks to our fleet, to cut down on pollution and fuel. `}
                                <Link to={'/about'}>Everything we do</Link>
                                {` is focused on efficiency - through education, technology and high quality employees.`}
                            </Typography>
                        </Box>
                    </Box>
                )}
                {selection === 'plant-health' && (
                    <Box id={'plant-health'}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>We care about your plants.</Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`A healthy landscape starts with healthy plants. We take the time to make sure that all of your plants get the proper amount of nutrients to thrive and look their very best. We test your soil frequently and amend as necessary to maintain an optimal pH balance for all of your growing plants..`}
                            </Typography>
                        </Box>
                    </Box>
                )}
                {selection === 'sustainability' && (
                    <Box id={'sustainability'}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>We are landscape stewards.</Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`As stewards of the landscape we are acutely aware of how our work impacts the environment. With this always in mind, we make active decisions to leave your property healthier than we found it.`}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>Sustainable landscape practices.</Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`A healthy environment is the best defense against invasive plant species, so we work with the lowest impact chemicals and we use drought-tolerant plants, drip-line irrigation and organic herbicides as much as possible. Educating our team on environmentally friendly landscape practices is more difficult and time consuming than spraying RoundUp on everything, but that's a price we're happy to pay.`}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>Steps towards a sustainable future.</Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`As we work toward healthier properties across Northern California, we are also working toward sustainability goals within our company. Within five years we plan to be close to Net 0 Carbon, with the majority of our fleet running hybrid trucks and electric equipment.`}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>Daily practices for a healthy company.</Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`All of our Serpico branches are strategically located to keep our routes tight, so crews spend less time sitting in traffic and more time maintaining beautiful landscapes. We are a `}
                                <a
                                    href={
                                        'https://greenbusinessca.org/?gclid=Cj0KCQiA-K2MBhC-ARIsAMtLKRvvLBTGnW5F6MvR6izh0_TLZC-ApPEfq5OXRyDxUGwbgJE6u_oFGtcaAi1nEALw_wcB'
                                    }
                                >
                                    California Certified Green Business
                                </a>
                                {` and our teams are `}
                                <a href={'https://www.rescapeca.org/'}>ReScape Qualified Professionals</a>
                                {`, specifically trained to care for the unique landscape of Northern California.`}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                <Typography variant={'h4'}>We stay ahead of the game.</Typography>
                <Typography sx={{ mt: 2 }}>
                    Education helps us keep your landscape healthier, using fewer natural resources. It's better for the
                    budget and the planet. That's why our staff works hard to earn industry licenses and certifications
                    and participate in networks that improve our sustainable practices.
                </Typography>
                <Box
                    sx={{
                        mt: 6,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-around',
                        flexWrap: 'wrap',
                    }}
                >
                    <img
                        alt={'qwel logo'}
                        src={qwel}
                        width={150}
                        height={'auto'}
                        style={{ maxWidth: '100%', margin: 8 }}
                    />
                    <img
                        alt={'irrigation alliance logo'}
                        src={ia}
                        width={80}
                        height={'auto'}
                        style={{ maxWidth: '100%', margin: 8 }}
                    />
                    <img
                        alt={'rescape logo'}
                        src={rescape}
                        width={200}
                        height={'auto'}
                        style={{ maxWidth: '100%', margin: 8 }}
                    />
                    <img
                        alt={'alameda logo'}
                        src={alameda}
                        width={100}
                        height={'auto'}
                        style={{ maxWidth: '100%', margin: 8 }}
                    />
                </Box>
            </Box>
            <Divider sx={{ backgroundColor: 'primary.light', mt: 8, mb: 8, ml: -8, mr: -8 }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: 8 }}>
                <Typography variant={'h5'} sx={{ mb: 3, textAlign: 'center' }}>
                    We are proud to work with the following suppliers:
                </Typography>
                <img alt={'rain bird logo'} src={rainBird} width={330} height={'auto'} style={{ maxWidth: '100%' }} />
                <img
                    alt={'greenworks logo'}
                    src={greenWorks}
                    width={330}
                    height={'auto'}
                    style={{ maxWidth: '100%', marginBottom: 24 }}
                />
                <img alt={'booster logo'} src={booster} width={330} height={'auto'} style={{ maxWidth: '100%', marginBottom: 24 }} />
                <img alt={'watersavers logo'} src={watersavers} width={330} height={'auto'} style={{ maxWidth: '100%' }} />
            </Box>
        </Box>
    );
};
