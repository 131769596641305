import { Box, Typography } from '@mui/material';

export const AboutSerpico: React.FC = () => (
    <>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>About Serpico</Typography>
            <Box sx={{ width: '100%', mt: 2, pb: '56.25%', position: 'relative' }}>
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/2_oiKvB7OEo"
                    frameBorder={'0'}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0 }}
                />
            </Box>
            <Typography sx={{ mt: 2 }}>
                About Serpico: Your Trusted Partner in Sustainable Landscaping
                <br /><br />
                Dive deeper into the ethos, values, and commitments that underpin our distinguished services. Our dedicated approach to landscaping, grounded in education and sustainability, sets us apart in the industry.
                <br /><br />
                Rooted in Excellence and Sustainability
                <br />
                At Serpico, our dedication goes beyond just landscaping; we are committed to nurturing landscapes from the roots up. Our team is ingrained with a deep understanding of the vital importance of plant health and resource conservation, which allows us to offer a distinctive and caring approach to landscaping. Join us as we share our story and the principles that guide our work.
                <br /><br />
                Education: The Cornerstone of Healthier Landscapes
                <br />
                We believe that an educated approach is key to fostering lush, vibrant, and sustainable landscapes. This conviction drives us to continually seek industry licenses and certifications, ensuring that our staff is equipped with the latest knowledge and skills to bring the best to your green spaces.
                <br /><br />
                Meet the People Behind Serpico
                <br />
                Our team is full of passionate people, who work tirelessly to redefine the norms of landscaping. Their dedication and expertise ensure that every project we undertake resonates with quality, sustainability, and beauty.
            </Typography>
        </Box>
    </>
);
