import React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { Box } from '@mui/material';

export type LoaderProps = LinearProgressProps;

export const Loader: React.FC = () => {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 1301,
                width: '100%',
                '& > * + *': {
                    mt: 2,
                },
            }}
        >
            <LinearProgress color="primary" />
        </Box>
    );
};
