import { Box, Typography } from '@mui/material';
import boardwalk from '../../../assets/images/news/boardwalk.jpeg';

export const SerpicoCultureArticle: React.FC = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
        <Typography variant={'h4'}>Giving is a Circle: Serpico Culture</Typography>
        <img
            src={boardwalk}
            style={{ width: '100%', height: 'auto', marginTop: 16 }}
            alt={'A beautifully landscaped wooden boardwalk'}
        />
        <Typography
            component={'blockquote'}
            sx={{
                m: 4,
                ml: 'auto',
                mr: 'auto',
                maxWidth: 600,
                pl: 2,
                borderLeft: '8px solid white',
                borderLeftColor: 'primary.main',
            }}
        >
            {`"When we give back to a community that supports us, we create a long-lasting relationship with the people in our area who we love and care about."`}
            <b>{` — Peter Novak, Serpico President`}</b>
        </Typography>
        <Typography sx={{ mt: 2, mb: 2 }}>
            {`Serpico's philosophy on giving back to our community is shared by many landscape companies across the country. We're honored to be part of such a giving industry. Learn more about the unique ways Serpico and fellow landscapers help our neighbors in `}
            <a href={'http://www.modernpubsonline.com/0A3ofua/NovDec2021/html/index.html?page=26&origin=reader'}>
                this article
            </a>
            {` in The Landscape Professional magazine.`}
        </Typography>
        <a
            style={{ display: 'block' }}
            href={'http://www.modernpubsonline.com/0A3ofua/NovDec2021/html/index.html?page=26&origin=reader'}
            target={'_blank'}
            rel={'noreferrer'}
        >
            <img
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                src="https://news.bayareahoalandscape.com/content/images/2022/01/giving-back-article-1.png"
                alt={'Magazine spread with a feature article'}
            />
        </a>
    </Box>
);
