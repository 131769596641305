import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import montageCrew from '../../../assets/images/news/montage-crew.jpeg';

export const InvestingArticle: React.FC = () => (
    <>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Why Invest in Your HOA Landscaping?</Typography>
            <Box sx={{ width: '100%', mt: 2, pb: '56.25%', position: 'relative' }}>
                <img
                    src={montageCrew}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                    alt={'A crew working on cleanup after a landscaping job'}
                />
            </Box>
            <Typography sx={{ mt: 2 }}>
                <a href={'https://magazine.realtor/daily-news/2014/10/14/landscaping-boosts-home-values-up-to-12'}>
                    Realtor
                </a>
                <sup>®</sup>
                {` Magazine in San Leandro  reports that landscaping improves a home's overall value by as much as 12%. For Homeowners Association (HOA) communities, which on average already have homes valued at `}
                <a href={'https://gogladly.com/blog/do-hoas-really-increase-property-values/'}>5% to 6%</a>
                {` higher than those in non-HOA communities, the increase can be even higher, depending on the landscaping techniques used.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`An HOA's core objective is to maintain and increase long-term property value, so it's critical to determine how landscape services are a part of that. Here's when you should invest in landscape design and maintenance for your HOA and how it can improve your short- and long-term return on investment (ROI).`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Occupancy high? Now is the time to invest in landscaping service</Typography>

            <Typography sx={{ mt: 2 }}>
                {`The value of investing in your HOA property while occupancy is high, before the next economic downturn, is a smart return on available resources. When occupancy is lower during a recession, your HOA community doesn't have as many tenants paying HOA dues.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Fewer paying Homeowners cut into your budget for landscaping services and other community property improvements. This can begin a vicious cycle; your property becomes less appealing to potential buyers, which perpetuates the problem of lower occupancy rates. Invest in upgrades to your community now, when occupancy is high, and you have more budget to work with.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Landscaping offers both immediate and long-term ROI</Typography>

            <Typography sx={{ mt: 2 }}>
                {`Landscaping is unique in that it offers both types of ROI — immediate and long-term. Property improvements that impact the way the community look, such as curb appeal and maintenance, often result in new home buyers coming to the area.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`New membership gives an immediate boost to your budget, which you can then use to invest in additional upgrades to the property. Over time, ongoing improvement of the property continues to attract new buyers, returning value long after the initial investment has been made.`}
            </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Not all landscaping is created equal</Typography>

            <Typography sx={{ mt: 2 }}>
                {`There's landscape maintenance, and then there's`}
                <i>landscaping</i>
                {`. Basic maintenance for HOA properties can be a good choice for companies looking to control costs; however, it's important to develop a long-term strategy for your HOA's landscaping.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Increasing curb appeal and desirability, as well as reducing the time that open properties are on the market allows you to invest in raising overall value beyond controlling costs. While basic landscaping services can help you maintain your HOA, planning landscaping designed to manage irrigation and maximize available natural resources helps increase property values and facilitate growth with low turnover.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Additionally, investing in money-saving programs like `}
                <Link to={'/services/resource-conservation'}>water conservation and water-wise planting</Link>
                {` helps offset the costs of landscaping, ensuring the continued development and occupancy of your HOA, while keeping cost inflation under control.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Other ways to boost value in your HOA ROI</Typography>

            <Typography sx={{ mt: 2 }}>
                {`There are a number of ways to boost the return on investment in your HOA community areas. You can:`}
            </Typography>
            <Box sx={{ ml: 4 }}>
                <Typography variant={'h6'} color={'primary'} sx={{ mt: 2 }}>
                    {`Renovate Amenities`}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {` Is your pool in need of a fresh coat of paint? Does your workout room lack enough exercise equipment for multiple residents to use? Your HOA's amenities are an important part of the draw to your community, so it's important to make sure they're in good repair.`}
                </Typography>

                <Typography variant={'h6'} color={'primary'} sx={{ mt: 2 }}>
                    {`Redesign common-area landscaping`}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {` The landscaping around common areas like the pool, dog park and walkways are the most visible across your property. `}
                    <Link to={'/services/landscape-enhancements'}>Redesign these areas</Link>
                    {` to make the most impact.`}
                </Typography>

                <Typography variant={'h6'} color={'primary'} sx={{ mt: 2 }}>
                    {`Pressure wash and paint exteriors`}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {`Keep the exterior of community buildings clean by pressure-washing them regularly and repainting as needed. Encourage Homeowners to keep their exteriors maintained as well.`}
                </Typography>

                <Typography variant={'h6'} color={'primary'} sx={{ mt: 2 }}>
                    {`Replace unsightly bushes and trees`}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {`Don't be afraid to uproot bushes or trees that are an eyesore. An experienced landscaping professional can help you design an attractive and efficient replacement.`}
                </Typography>

                <Typography variant={'h6'} color={'primary'} sx={{ mt: 2 }}>
                    {`Add lighting to important features`}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {`Lighting is one of the biggest draws of an HOA community; it makes residents feel safer and highlights attractive landscaping. Add lighting to pathways, fountains, tennis courts, pools, and other valuable features.`}
                </Typography>

                <Typography variant={'h6'} color={'primary'} sx={{ mt: 2 }}>
                    {`Improve parking areas`}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {`Homeowners want safe, convenient parking. Make sure your parking areas are spacious, striped, and in easily accessible locations.`}
                </Typography>

                <Typography variant={'h6'} color={'primary'} sx={{ mt: 2 }}>
                    {`Add a dog park or et relief area`}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {`If your HOA doesn't already have a dog park or dedicated area to pet relief, consider adding one. Include doggie bag dispensers and trash cans at entrances and exits for convenience and cleanliness.`}
                </Typography>

                <Typography variant={'h6'} color={'primary'} sx={{ mt: 2 }}>
                    {`Add outdoor play areas for children`}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {`Families want to have a place to take young children to run and play without having to go far from home. Add a playground in a central location if you don't already have one.`}
                </Typography>

                <Typography variant={'h6'} color={'primary'} sx={{ mt: 2 }}>
                    {`Implement online payment and maintenance`}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {`Make it convenient for residents to pay monthly dues or request maintenance of community facilities online. Online payment portals can drastically reduce missed or late dues!`}
                </Typography>

                <Typography variant={'h6'} color={'primary'} sx={{ mt: 2 }}>
                    {`Consider non-traditional amenities`}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    {`Keep up with the times and give your HOA an edge over competitors in the area by offering electric car chargers, community gardens, a telecommuting center and other uncommon but highly desirable amenities.`}
                </Typography>
            </Box>

            <Typography sx={{ mt: 2 }}>
                {`Landscaping is a great start to improving the overall ROI of your Homeowners Association. Realtor`}
                <sup>®</sup>
                {` Magazine suggests that the most impactful elements of landscaping include the sophistication of the design, as well as the diversity and size of plants used.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`When you hire a landscaping company with a proven track record, it ensures that you get the most value for your investment while balancing the best features for your neighborhood.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Why choose Serpico?</Typography>

            <Typography sx={{ mt: 2 }}>
                {`At Serpico, we're raising the bar on Northern California commercial landscaping. We work closely with HOA Boards of Directors to understand the vision and bring it to fruition. It's important that your landscape design tell your property's story, and our expert landscaping professionals can help make sure your landscaping sends the right message. We then develop a strategic approach to create a design that maximizes visual impact and efficiency, while keeping maintenance costs in line.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`We strive to deliver quality, excellence and precision in every service we provide. You'll receive proactive information and data that you can address board members and management teams with, so you're always prepared. We're market leaders in effectively combining water conservation with creating lush, healthy landscaping`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Are you ready for a holistic plan to achieve your community's landscaping goals while yielding cost‑savings and a long‑term ROI? `}
                <Link to={'/request-proposal'}>Request a Proposal</Link>
                {` to raise the bar on your HOA landscape.`}
            </Typography>
        </Box>
    </>
);
