import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import treeService from '../../../assets/images/news/SalmonRose.jpg';

export const TechnologyArticle: React.FC = () => (
    <>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Harnessing Technology to Revolutionize Landscape Services at Serpico</Typography>
            <Box sx={{ width: '100%', mt: 2, pb: '56.25%', position: 'relative' }}>
                <img
                    src={treeService}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                    alt={'A Salmon Colored Rose in a Beautiful Bush'}
                />
            </Box>
            <Typography sx={{ mt: 2 }}>
                {`In an era where technology permeates every aspect of life, the landscaping industry is no exception.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`At Serpico, we are continuously redefining our practices, harnessing the power of the latest technologies to offer our clients unparalleled landscape service experiences. Our aim is to not only enhance the beauty and functionality of outdoor spaces but to also make our operations more efficient, eco-friendly, and client-oriented. If you've known the comfort of a mature tree shading your home or the tragedy of a diseased tree falling on it, you understand the value in taking good care of urban trees. Our trees can offer so much if we care for them and they can damage so much if we ignore them.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Embracing the Tech-Advancement</Typography>
            <Typography sx={{ mt: 2 }}>
                {`We firmly believe that integrating technology into our processes is the key to staying ahead in the increasingly competitive landscaping sector. Whether it's through state-of-the-art landscaping software for better project management, the use of drones for precise site mapping, or advanced irrigation systems for efficient water use, we constantly seek opportunities to innovate and improve.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`One significant advancement we have adopted is the use of cutting-edge equipment from Greenworks Commercial, the leading provider of battery-powered outdoor equipment. By transitioning to their powerful, noiseless, and emission-free tools, we are not only improving our efficiency but also making a commitment to environmental sustainability.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>The Impact on Our Services</Typography>
            <Typography sx={{ mt: 2 }}>
                {`The use of technology enables us to deliver precise, consistent, and high-quality results. For instance, landscaping software allows us to plan, visualize, and manage projects more effectively, ensuring we meet our clients' expectations and deadlines. The implementation of A.I. driven GPS imagery and measurement improves our site evaluations, providing detailed and accurate data that enables us to maintain, design and execute landscape projects with precision.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`The utilization of weather based smart irrigation systems allows us to manage water usage efficiently, promoting lush, healthy landscapes while conserving our most valuable resource - water. Meanwhile, the electric equipment from Greenworks Commercial ensures we can perform our tasks more quietly and cleanly, reducing our carbon footprint and offering a more pleasant experience for our clients.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>The Future of Landscaping</Typography>
            <Typography sx={{ mt: 2 }}>
                {`As we look towards the future, we are excited about the possibilities technology brings to the landscaping industry. Automation, artificial intelligence (AI), and even augmented reality (AR) hold enormous potential for further enhancing our service delivery and client experience.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`At Serpico, we are committed to continuous learning and adaptation, always staying on top of industry trends and tech advancements. By doing so, we can confidently deliver exceptional landscape services that combine the best of tradition and innovation.
`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography sx={{ mt: 2 }}>
                {`At Serpico, embracing technology is not just about improving efficiency and productivity. It's about enhancing the way we interact with our clients, how we manage our resources, and how we contribute to a sustainable future. It's about providing superior landscape services that meet and exceed the evolving expectations of our clients. As we continue to lead in this transformation, we are eager to shape the future of landscaping services, delivering nothing but the best for our clients across Northern California. At Serpico Landscaping, excellence, innovation, and sustainability are more than just words – they are our commitment.`}
            </Typography>
        </Box>
    </>
);
