import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Routes from './routing/routes';
import { FirebaseProvider } from './contexts/FirebaseContext';
import { SerpicoTheme } from './themes';

export const App: React.FC = () => (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={SerpicoTheme}>
            <CssBaseline />
            <FirebaseProvider>
                <Routes />
            </FirebaseProvider>
        </ThemeProvider>
    </StyledEngineProvider>
);
