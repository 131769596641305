import { SvgIcon, SvgIconProps } from '@mui/material';

export const SerpicoIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 300 138">
        <path d="M94.14 96.89H19.35V116.24H94.14V96.89Z" />
        <path d="M280.47 19.34H205.68V38.69H280.47V19.34Z" />
        <path d="M280.47 58.12H205.68V77.47H280.47V58.12Z" />
        <path d="M280.47 96.89H205.68V116.24H280.47V96.89Z" />
        <path d="M187.31 58.12H112.52V77.47H187.31V58.12Z" />
        <path d="M187.31 96.89H112.52V116.24H187.31V96.89Z" />
    </SvgIcon>
);
