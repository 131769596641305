import { useCallback, useEffect, useState } from 'react';
import { Box, Typography, SxProps, useTheme, useMediaQuery, Button, IconButton, Fade, Tooltip } from '@mui/material';
import { scroller, animateScroll } from 'react-scroll';
import mission from '../../assets/images/mission/mission.png';
import conservation from '../../assets/images/mission/conservation.png';
import sustainability from '../../assets/images/mission/sustainability.png';
import plantHealth from '../../assets/images/mission/plantHealth.png';
import advancedTechnologies from '../../assets/images/mission/advancedTechnologies.png';
import aboutSerpico from '../../assets/images/mission/aboutSerpico.png';
import { CallToActionConfig, CallToActionGrid } from '../../components/navigation';
import { useNavigate, useParams } from 'react-router-dom';
import { Conservation, Mission, Sustainability, PlantHealth, AdvancedTechnologies, AboutSerpico } from './missions';
import { ArrowBack, ArrowForward, ArrowUpward } from '@mui/icons-material';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

type CTAKey =
    | 'ourMission'
    | 'conservation'
    | 'sustainability'
    | 'plantHealth'
    | 'advancedTechnologies'
    | 'aboutSerpico';

    const CTA_SLUGS: { [key in CTAKey]: string } = {
        ourMission: 'our-mission',
        conservation: 'conservation',
        sustainability: 'sustainability',
        plantHealth: 'plant-health',
        advancedTechnologies: 'advanced-technologies',
        aboutSerpico: 'about-serpico',
    };

const transparentBorder: SxProps = {
    borderWidth: 0,
    borderBottomWidth: 8,
    borderStyle: 'solid',
    borderColor: 'transparent',
};
const selected: SxProps = {
    borderWidth: 0,
    borderBottomWidth: 8,
    borderStyle: 'solid',
    borderColor: 'primary.main',
};

const handleScrollTo = (id: string) =>
    setTimeout(
        () =>
            scroller.scrollTo(id, {
                duration: 1000,
                delay: 0,
                smooth: true,
                offset: -100,
            }),
        100
    );

export const MissionPage: React.FC = () => {
    const { missions: selection } = useParams();
    const [showScrollToTop, setShowScrollToTop] = useState(selection !== undefined);
    const navigate = useNavigate();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (selection && (sm || window.innerHeight < 900)) handleScrollTo(selection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useScrollPosition(
        ({ currPos }) => {
            setShowScrollToTop(currPos.y >= window.innerHeight);
        },
        [],
        undefined,
        true,
        200
    );

    const getPageDetails = useCallback(
        (props: { label: string; slug: string; image: string }): CallToActionConfig => {
            const { image, slug, label } = props;
            return {
                src: image,
                title: label,
                props: {
                    onClick: () => {
                        if (selection === slug) {
                            navigate('/mission');
                        } else {
                            navigate(`/mission/${slug}`);
                            if (sm || window.innerHeight < 900) handleScrollTo(slug);
                        }
                    },
                    sx: selection === slug ? selected : transparentBorder,
                },
            };
        },
        [selection, sm, navigate]
    );

    const nextArticle = useCallback(
        (step: 1 | -1) => {
            const keys = Object.keys(CTA_SLUGS);
            let nextSlug: string = selection || '';
            if (!selection) {
                nextSlug = CTA_SLUGS[keys[0] as CTAKey];
                navigate(`/mission/${nextSlug}`);
            } else {
                const currentIndex = keys.findIndex((key) => CTA_SLUGS[key as CTAKey] === selection);
                const nextIndex = (currentIndex + keys.length + step) % keys.length;
                nextSlug = CTA_SLUGS[keys[nextIndex] as CTAKey];
                navigate(`/mission/${nextSlug}`);
            }
            handleScrollTo(nextSlug);
        },
        [navigate, selection]
    );

    return (
        <Box sx={{ maxWidth: 1200, m: '0 auto', mt: { xs: 7, sm: 8 }, p: { xs: 2, sm: 8 } }}>
            <Typography variant={'h3'} sx={{ fontWeight: 700 }}>
                Find out how we do landscaping differently
            </Typography>

            <Box sx={{ width: '100%', m: '0 auto', maxWidth: { xs: 400, sm: 'unset' } }}>
                <CallToActionGrid
                    sx={{ mt: 6 }}
                    items={[
                        getPageDetails({
                            slug: CTA_SLUGS.ourMission,
                            label: 'Serpico’s Mission',
                            image: mission,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.conservation,
                            label: 'Conservation',
                            image: conservation,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.sustainability,
                            label: 'Sustainability',
                            image: sustainability,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.plantHealth,
                            label: 'Plant Health',
                            image: plantHealth,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.advancedTechnologies,
                            label: 'Advanced Technologies',
                            image: advancedTechnologies,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.aboutSerpico,
                            label: 'About Serpico',
                            image: aboutSerpico,
                        }),
                    ]}                    
                />
                {selection === CTA_SLUGS.ourMission && (
                    <Box id={CTA_SLUGS.ourMission}>
                        <Mission />
                    </Box>
                )}
                {selection === CTA_SLUGS.conservation && (
                    <Box id={CTA_SLUGS.conservation}>
                        <Conservation />
                    </Box>
                )}
                {selection === CTA_SLUGS.plantHealth && (
                    <Box id={CTA_SLUGS.plantHealth}>
                        <PlantHealth />
                    </Box>
                )}
                {selection === CTA_SLUGS.sustainability && (
                    <Box id={CTA_SLUGS.sustainability}>
                        <Sustainability />
                    </Box>
                )}
                {selection === CTA_SLUGS.advancedTechnologies && (
                    <Box id={CTA_SLUGS.advancedTechnologies}>
                        <AdvancedTechnologies />
                    </Box>
                )}
                {selection === CTA_SLUGS.aboutSerpico && (
                    <Box id={CTA_SLUGS.aboutSerpico}>
                        <AboutSerpico />
                    </Box>
                )}
            </Box>

            {selection && (
                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                    <Button startIcon={<ArrowBack />} onClick={() => nextArticle(-1)}>
                        Previous
                    </Button>
                    <Button endIcon={<ArrowForward />} onClick={() => nextArticle(1)}>
                        Next
                    </Button>
                </Box>
            )}
            <Fade in={showScrollToTop}>
                <Tooltip title={'Back to top'}>
                    <IconButton
                        onClick={() => {
                            animateScroll.scrollToTop({
                                duration: 1000,
                                delay: 0,
                                smooth: true,
                            });
                        }}
                        sx={{ position: 'fixed', right: 16, top: '50%', transform: 'translateY(-50%)' }}
                    >
                        <ArrowUpward />
                    </IconButton>
                </Tooltip>
            </Fade>
        </Box>
    );
};
