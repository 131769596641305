import {
    Box,
    Button,
    Typography,
    FormHelperText,
    Grid,
    IconButton,
    CircularProgress,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Select,
    MenuItem,
    InputLabel
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useScriptRef from '../hooks/useScriptRef';
import { AttachFile, Clear } from '@mui/icons-material';
import { useState } from 'react';
import { FileUploadWithDragDrop } from '../components/inputs/FileUpload';
import { firebaseAddProposalRequest, firebaseUploadFile } from '../firebase';
import { Link } from 'react-router-dom';
import { ProposalRequest } from '../types/proposal';
import useAuth from '../hooks/useAuth';
import useQuery from '../hooks/useQuery';

const initialData: ProposalRequest & { submit?: any } = {
    services: {
        monthlyMaintenance: false,
        landscapeEnhancements: false,
        treeWork: false,
        irrigationManagement: false,
        other: false,
    },
    budget: "",
    submit: null,
};

const SuccessBox: React.FC = () => (
    <Box sx={{ textAlign: 'center' }}>
        <Typography variant={'h6'} color={'primary'}>
            SUCCESS
        </Typography>
        <Typography>Your request has been received —</Typography>
        <Typography>we'll be in touch soon!</Typography>
        <Box sx={{ color: 'primary.contrastText' }}>
            <Button fullWidth variant={'contained'} component={Link} to={'/'} sx={{ mt: 2, minWidth: 100 }}>
                Home
            </Button>
        </Box>
    </Box>
);

export const RequestProposalPage: React.FC = (props) => {
    const { user } = useAuth();
    const query = useQuery();
    const scriptedRef = useScriptRef();
    const [attachment, setAttachment] = useState<File | null>(null);

    const defaultService = query.get('service');
    const initialServices: ProposalRequest['services'] = {
        monthlyMaintenance: defaultService === 'landscape-maintenance',
        landscapeEnhancements: defaultService === 'landscape-enhancements',
        treeWork: defaultService === 'tree-work',
        irrigationManagement: defaultService === 'irrigation-management',
        other: defaultService === 'other',
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: 1200,
                minHeight: '100vh',
                m: '0 auto',
                p: { xs: 2, sm: 8 },
            }}
        >
            <Box sx={{ maxWidth: 600, width: '100%', m: { xs: 2, sm: 8 } }}>
                <Formik
                    initialValues={{ ...initialData, services: initialServices }}
                    validationSchema={Yup.object().shape({
                        services: Yup.object()
                            .shape({
                                monthlyMaintenance: Yup.boolean(),
                                landscapeEnhancements: Yup.boolean(),
                                treeWork: Yup.boolean(),
                                irrigationManagement: Yup.boolean(),
                                other: Yup.boolean(),
                            })
                            .test({
                                name: 'one-true',
                                message: 'Please select a service',
                                test: (val) =>
                                    Boolean(
                                        val.monthlyMaintenance ||
                                            val.landscapeEnhancements ||
                                            val.treeWork ||
                                            val.irrigationManagement ||
                                            val.other
                                    ),
                            }),
                    })}
                    onSubmit={(values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                        try {
                            const proposal = { ...values };
                            delete proposal.submit;
                            if (!attachment) {
                                firebaseAddProposalRequest({ ...proposal, userID: user?.uid || '' }).then((res) => {
                                    resetForm();
                                    setAttachment(null);
                                    if (scriptedRef.current) {
                                        setStatus({ success: true });
                                        setSubmitting(false);
                                    }
                                });
                            } else {
                                firebaseUploadFile({
                                    category: `PROPOSAL_MAP`,
                                    file: attachment,
                                    onProgress: (percent) => {
                                        /*console.log(percent)*/
                                    },
                                    onSuccess: (docId) => {
                                        firebaseAddProposalRequest(
                                            { ...proposal, userID: user?.uid || '' },
                                            docId
                                        ).then((res) => {
                                            resetForm();
                                            setAttachment(null);
                                            if (scriptedRef.current) {
                                                setStatus({ success: true });
                                                setSubmitting(false);
                                            }
                                        });
                                    },
                                    onError: (err) => {
                                        if (scriptedRef.current) {
                                            setStatus({ success: false });
                                            setErrors({ submit: err.message });
                                            setSubmitting(false);
                                        }
                                    },
                                });
                            }
                        } catch (err: any) {
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        setFieldTouched,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        status,
                    }) => {
                        return status?.success ? (
                            <SuccessBox />
                        ) : (
                            <form noValidate onSubmit={handleSubmit}>
                                <Typography variant={'h4'} sx={{ textAlign: 'center', mb: { xs: 2, sm: 8 } }}>
                                    Request Proposal
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: { xs: 'column', sm: 'row' },
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <FormControl
                                                component="fieldset"
                                                variant="standard"
                                                error={Boolean(errors.services)}
                                            >
                                                <Typography component="legend" sx={{ color: 'inherit', mb: 2 }}>
                                                    I need a proposal for:
                                                </Typography>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={values.services.monthlyMaintenance}
                                                                onChange={(e, checked) => {
                                                                    setFieldTouched('services', true);
                                                                    setFieldValue(
                                                                        'services.monthlyMaintenance',
                                                                        checked
                                                                    );
                                                                }}
                                                                name="monthly-maintenance"
                                                            />
                                                        }
                                                        label="Monthly Landscape Maintenance"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={values.services.landscapeEnhancements}
                                                                onChange={(e, checked) => {
                                                                    setFieldTouched('services', true);
                                                                    setFieldValue(
                                                                        'services.landscapeEnhancements',
                                                                        checked
                                                                    );
                                                                }}
                                                                name="landscape-enhancements"
                                                            />
                                                        }
                                                        label="Landscape Enhancements"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={values.services.treeWork}
                                                                onChange={(e, checked) => {
                                                                    setFieldTouched('services', true);
                                                                    setFieldValue('services.treeWork', checked);
                                                                }}
                                                                name="tree-work"
                                                            />
                                                        }
                                                        label="Tree Work"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={values.services.irrigationManagement}
                                                                onChange={(e, checked) => {
                                                                    setFieldTouched('services', true);
                                                                    setFieldValue(
                                                                        'services.irrigationManagement',
                                                                        checked
                                                                    );
                                                                }}
                                                                name="irrigation-management"
                                                            />
                                                        }
                                                        label="Irrigation Management"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={values.services.other}
                                                                onChange={(e, checked) => {
                                                                    setFieldTouched('services', true);
                                                                    setFieldValue('services.other', checked);
                                                                }}
                                                                name="other"
                                                            />
                                                        }
                                                        label="Other"
                                                    />
                                                </FormGroup>
                                                {Boolean(touched.services) && Boolean(errors.services) && (
                                                    <FormHelperText>{errors.services}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="budget-label">Budget</InputLabel>
                                            <Select
                                                labelId="budget-label"
                                                id="budget"
                                                label="Budget"
                                                name={'budget'}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                fullWidth
                                            >
                                                <MenuItem value={"I don't have one"}>I don't have one</MenuItem>
                                                <MenuItem value={"I'm not sure"}>I'm not sure</MenuItem>
                                                <MenuItem value={"Up to $1,000"}>Up to $1,000</MenuItem>
                                                <MenuItem value={"Up to $5,000"}>Up to $5,000</MenuItem>
                                                <MenuItem value={"Over $5,000"}>Over $5,000</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    {!attachment && (
                                        <Grid item xs={12}>
                                            <FileUploadWithDragDrop
                                                label={`Include a Map`}
                                                dropZoneOptions={{
                                                    accept: 'application/pdf, .pdf, image/jpeg, image/png',
                                                    maxFiles: 1,
                                                    multiple: false,
                                                    onDropAccepted: (files, e) => {
                                                        setAttachment(files[0]);
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    )}
                                    {attachment && (
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <AttachFile color={'primary'} />
                                                <Typography
                                                    color={'primary'}
                                                    sx={{
                                                        ml: 1,
                                                        flex: '1 1 0px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {attachment.name}
                                                </Typography>
                                                <IconButton color={'primary'} onClick={() => setAttachment(null)}>
                                                    <Clear />
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        {errors.submit && (
                                            <Box sx={{ mb: 2 }}>
                                                <FormHelperText error>{errors.submit}</FormHelperText>
                                            </Box>
                                        )}
                                        <Button
                                            fullWidth
                                            type={'submit'}
                                            variant={'contained'}
                                            color={'primary'}
                                            sx={{ mt: 2 }}
                                            disabled={
                                                isSubmitting ||
                                                Boolean(errors.services)
                                            }
                                        >
                                            {!isSubmitting ? (
                                                'Submit'
                                            ) : (
                                                <CircularProgress color={'inherit'} size={36} />
                                            )}
                                        </Button>
                                        {status?.success && <Typography color={'primary'}>Success!</Typography>}
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
};
