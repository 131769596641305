import { Box, Typography } from '@mui/material';

export const AdvancedTechnologies: React.FC = () => (
    <>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Advanced Technologies</Typography>
            <Box sx={{ width: '100%', mt: 2, pb: '56.25%', position: 'relative' }}>
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/6gr8YxKZdn8"
                    frameBorder={'0'}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0 }}
                />
            </Box>
            <Typography sx={{ mt: 2 }}>
                Advanced Technologies: Elevating Landscape Management with Serpico
                <br /><br />
                Serpico is revolutionizing the landscaping industry by marrying technology with nature. Discover how our advanced technologies not only set us apart from the competition but also redefine efficiency and effectiveness in landscape management.
                <br /><br />
                Technological Excellence in Landscaping
                <br />
                At Serpico, we are fervent believers in leveraging technology to facilitate exceptional service. From providing detailed insights into your landscape contract to fostering seamless communication, our tech-savvy approach ensures precision and clarity at every step. 
                <br /><br />
                Precision and Predictability
                <br />
                By utilizing cutting-edge technology, we bring an unprecedented level of precision to our projects. Our digital tools help us to meticulously plan and execute each task, ensuring you always know what to expect.
                <br /><br />
                Streamlined Communication for Satisfactory Outcomes
                <br />
                Our commitment to utilizing technology extends to fostering more effective communication with our clients.
            </Typography>
        </Box>
    </>
);
