import { Box, Typography } from '@mui/material';

export const Conservation: React.FC = () => (
    <>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Conservation</Typography>
            <Box sx={{ width: '100%', mt: 2, pb: '56.25%', position: 'relative' }}>
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/v7De4CqQkhI"
                    frameBorder={'0'}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0 }}
                />
            </Box>
            <Typography sx={{ mt: 2 }}>
                {`As the CEO of Serpico, Peter Novak takes great pride in helping our clients reduce their water usage. At Serpico, we take a comprehensive approach to water conservation in HOA landscapes. We consider every aspect of the environmental features of each property to help conserve as much water as possible, while ensuring the overall health and beauty of the landscape.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`We understand that water is a precious resource, especially in California's dry climate. That's why we prioritize water conservation in all of our landscaping projects. By using drought-tolerant plants, optimizing irrigation systems, and implementing `}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Our team of professionals carefully analyzes the soil, plant types, and local climate to create customized solutions for each property we work on. We also take cost savings into account and work diligently to find the most cost-effective solutions for our clients.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`At Serpico, we believe in responsible environmental stewardship, and we take pride in helping our clients do the same. By working together, we can create beautiful, sustainable landscapes that benefit both the environment and the community.`}
            </Typography>
        </Box>
    </>
);
