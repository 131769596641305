import { Box, Button, Typography, TextField, FormHelperText, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useScriptRef from '../../hooks/useScriptRef';
import { useEffect, useState } from 'react';
import useQuery from '../../hooks/useQuery';

const SuccessBox: React.FC = () => (
    <Box sx={{ textAlign: 'center' }}>
        <Typography variant={'h6'} color={'primary'}>
            SUCCESS
        </Typography>
        <Typography>Your password has been updated —</Typography>
        <Typography>Let's get started!</Typography>
        <Box sx={{ color: 'primary.contrastText' }}>
            <Button
                fullWidth
                variant={'contained'}
                component={Link}
                to={'/login'}
                replace
                sx={{ mt: 2, minWidth: 100 }}
            >
                Log In
            </Button>
        </Box>
    </Box>
);

const ErrorBox: React.FC = () => (
    <Box sx={{ textAlign: 'center' }}>
        <Typography variant={'h6'} color={'error'}>
            Error
        </Typography>
        <Typography>There was a problem with your reset code —</Typography>
        <Typography>Try sending a new reset request.</Typography>
        <Box sx={{ color: 'primary.contrastText' }}>
            <Button
                fullWidth
                variant={'contained'}
                component={Link}
                to={'/forgot-password'}
                sx={{ mt: 2, minWidth: 100 }}
            >
                Reset Password
            </Button>
        </Box>
    </Box>
);

export const ResetPasswordPage: React.FC = (props) => {
    const scriptedRef = useScriptRef();
    const navigate = useNavigate();
    const { firebaseResetPassword, firebaseVerifyPasswordResetCode } = useAuth();
    const [codeStatus, setCodeStatus] = useState<'valid' | 'invalid' | 'loading'>('loading');
    const query = useQuery();
    const oobCode = query.get('oobCode');

    useEffect(() => {
        const checkCode = async () => {
            try {
                if (!oobCode) navigate('/');
                await firebaseVerifyPasswordResetCode(oobCode || '');
                setCodeStatus('valid');
            } catch (e) {
                setCodeStatus('invalid');
            }
        };
        checkCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                maxWidth: 1200,
                minHeight: '100vh',
                m: '0 auto',
                p: { xs: 2, sm: 8 },
            }}
        >
            <Box sx={{ maxWidth: 600, m: { xs: 2, sm: 8 } }}>
                {codeStatus === 'loading' && <CircularProgress color={'primary'} size={48} />}
                {codeStatus === 'invalid' && <ErrorBox />}
                {codeStatus === 'valid' && (
                    <Formik
                        initialValues={{
                            password: '',
                            submit: null,
                        }}
                        validationSchema={Yup.object().shape({
                            password: Yup.string().min(6).max(255).required('Required'),
                        })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            try {
                                await firebaseResetPassword(oobCode || '', values.password);
                                if (scriptedRef.current) {
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                }
                            } catch (err: any) {
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                    setSubmitting(false);
                                }
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            status,
                        }) => {
                            return status?.success ? (
                                <SuccessBox />
                            ) : (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Typography variant={'h4'} sx={{ textAlign: 'center' }}>
                                        Reset Password
                                    </Typography>
                                    <Typography sx={{ mt: 1, mb: { xs: 2, sm: 8 }, textAlign: 'center' }}>
                                        Enter your new password below.
                                    </Typography>
                                    <TextField
                                        id={'password'}
                                        name={'password'}
                                        type={'password'}
                                        label={'New Password'}
                                        variant={'outlined'}
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={touched.password && Boolean(errors.password)}
                                        helperText={touched.password && errors.password}
                                    />
                                    {errors.submit && (
                                        <Box sx={{ mt: 2 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                    <Button
                                        fullWidth
                                        type={'submit'}
                                        variant={'contained'}
                                        color={'primary'}
                                        sx={{ mt: 2 }}
                                        disabled={
                                            isSubmitting || Boolean(errors.password) || values.password.length < 6
                                        }
                                    >
                                        Update Password
                                    </Button>
                                </form>
                            );
                        }}
                    </Formik>
                )}
            </Box>
        </Box>
    );
};
