import { Box, Typography, SxProps, useTheme, useMediaQuery } from '@mui/material';
import { scroller } from 'react-scroll';

import technology from '../assets/images/about/technology.jpeg';
import conservation from '../assets/images/about/conservation.jpeg';
import enhancements from '../assets/images/services/enhancements.jpeg';
import treeCare from '../assets/images/services/tree-care.jpg';

import mower from '../assets/images/services/mower.jpeg';
import shovel from '../assets/images/services/shovel.jpeg';
import flowerBox from '../assets/images/services/flower-box.jpeg';

import { CallToActionConfig, CallToActionGrid } from '../components/navigation';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

type CTAKey = 'LandscapeMaintenance' | 'IrrigationManagement' | 'LandscapeEnhancements' | 'TreeCare';

const CTA_SLUGS: { [key in CTAKey]: string } = {
    LandscapeMaintenance: 'landscape-maintenance',
    IrrigationManagement: 'irrigation-management',
    LandscapeEnhancements: 'landscape-enhancements',
    TreeCare: 'tree-care',
};

const transparentBorder: SxProps = {
    borderWidth: 0,
    borderBottomWidth: 8,
    borderStyle: 'solid',
    borderColor: 'transparent',
};
const selected: SxProps = {
    borderWidth: 0,
    borderBottomWidth: 8,
    borderStyle: 'solid',
    borderColor: 'primary.main',
};

const handleScrollTo = (id: string) =>
    setTimeout(
        () =>
            scroller.scrollTo(id, {
                duration: 1000,
                delay: 0,
                smooth: true,
                offset: -100,
            }),
        100
    );

export const ServicesPage: React.FC = (props) => {
    const { service: selection } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (selection && (sm || window.innerHeight < 900)) handleScrollTo(selection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPageDetails = useCallback(
        (props: { label: string; slug: string; image: string }): CallToActionConfig => {
            const { image, slug, label } = props;
            return {
                src: image,
                title: label,
                props: {
                    onClick: () => {
                        if (selection === slug) {
                            navigate('/services');
                        } else {
                            navigate(`/services/${slug}`);
                            if (sm || window.innerHeight < 900) handleScrollTo(slug);
                        }
                    },
                    sx: selection === slug ? selected : transparentBorder,
                },
            };
        },
        [selection, sm, navigate]
    );

    return (
        <Box sx={{ maxWidth: 1200, m: '0 auto', mt: { xs: 7, sm: 8 }, p: { xs: 2, sm: 8 } }}>
            <Typography variant={'h3'} sx={{ fontWeight: 700 }}>
                What does your landscape need?
            </Typography>

            <Box sx={{ mt: 3 }}>
                <Typography variant={'h4'}>We raise the bar every way we can.</Typography>
                <Typography sx={{ mt: 2 }}>
                    In everything we do, we take a proactive approach to communication, education, resource conservation
                    and partnership. We want to be a valuable resource to you and your community. We achieve this
                    through personal attention and service.
                </Typography>
            </Box>

            <Box sx={{ width: '100%', m: '0 auto', maxWidth: { xs: 400, sm: 'unset' } }}>
                <CallToActionGrid
                    sx={{ mt: 6 }}
                    items={[
                        getPageDetails({
                            slug: CTA_SLUGS.LandscapeMaintenance,
                            label: 'Landscape Maintenance',
                            image: technology,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.IrrigationManagement,
                            label: 'Irrigation Management',
                            image: conservation,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.LandscapeEnhancements,
                            label: 'Landscape Enhancements',
                            image: enhancements,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.TreeCare,
                            label: 'Tree Care',
                            image: treeCare,
                        }),
                    ]}
                />
                {selection === CTA_SLUGS.LandscapeMaintenance && (
                    <Box id={CTA_SLUGS.LandscapeMaintenance}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>Maintenance is key.</Typography>
                            <img
                                src={mower}
                                style={{ width: '100%', height: 'auto', marginTop: 16 }}
                                alt={'A man pushing a lawn mower.'}
                            />
                            <Typography sx={{ mt: 2 }}>
                                {`A beautiful, healthy landscape increases quality of life as well as the property value of your community. Regular landscape maintenance by our highly trained crews can improve the health and longevity of your plants, conserve precious water resources and minimize damage to fences, sidewalks and other hardscape assets on your property.`}
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`Our crews work on a rotation schedule, which means the property is divided into zones and the crew maintains one zone every four weeks. This helps maximize our effectiveness on your property, and minimize stress on plants and homeowners. `}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>Technology makes the difference.</Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`We utilize the latest innovations to make sure our communications, landscape practices and environmental impact are always at their best. Our service operations are driven by the same cutting edge Azure cloud technology that powers fortune 100 companies, and we utilize real-time service management through Microsoft Dynamics for seamless service consistency and faster response times.`}
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`Learn more about how Serpico is `}
                                <Link to={'/about'}>raising the bar</Link>
                                {`.`}
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                <Link to={'/request-proposal?service=landscape-maintenance'}>Request a quote</Link>
                                {` for Landscape Maintenance.`}
                            </Typography>
                        </Box>
                    </Box>
                )}
                {selection === CTA_SLUGS.IrrigationManagement && (
                    <Box id={CTA_SLUGS.IrrigationManagement}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>Smart water management.</Typography>
                            <img
                                src={shovel}
                                style={{ width: '100%', height: 'auto', marginTop: 16 }}
                                alt={'A man digging a hole with a shovel.'}
                            />
                            <Typography sx={{ mt: 2 }}>
                                {`For more than three decades, Serpico has been on the front lines of water conservation and has been using the most efficient irrigation systems in landscaping. Serpico's water management specialists have certification through the `}
                                <a
                                    href={
                                        'https://www.irrigation.org/IA/Certification/0Get_Certified/IA/Certification/Certification.aspx?hkey=66a85aac-3da0-4e05-aecd-b5c7e968010a'
                                    }
                                >
                                    Irrigation Association
                                </a>
                                {` (IA) and `}
                                <a href={'https://www.qwel.net/'}>Qualified Water Efficient Landscapers</a>
                                {` (QWEL). Our team is also manufacturer-trained to install, troubleshoot and fine-tune the leading smart-controller platforms.`}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>Advanced water management planning.</Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`Our leadership team holds seats on `}
                                <i>two</i>
                                {` of the major municipal landscape water agency advisory councils. This unique involvement allows Serpico to serve as the voice of the landscape industry right where decisions about regulations, rebates and conservation strategies are being made. Our efforts in this arena have resulted in millions of gallons being saved through efficient methodologies and the latest technology, as well as the recent WaterSense legislation.`}
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`Learn more about our commitment to `}
                                <Link to={'/about/resource-conservation'}>resource conservation</Link>
                                {`.`}
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                <Link to={'/request-proposal?service=irrigation-management'}>Request a quote</Link>
                                {` for Irrigation Management.`}
                            </Typography>
                        </Box>
                    </Box>
                )}
                {selection === CTA_SLUGS.LandscapeEnhancements && (
                    <Box id={CTA_SLUGS.LandscapeEnhancements}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>Enhance your landscape.</Typography>
                            <img
                                src={flowerBox}
                                style={{ width: '100%', height: 'auto', marginTop: 16 }}
                                alt={'A large flower garden growing atop a trellis.'}
                            />
                            <Typography sx={{ mt: 2 }}>
                                {`Like most things in life, your landscape can get outdated. Over the years, plant material can become harder to maintain, irrigation systems lose efficiency and community needs change. Landscape enhancements can help remedy these issues. Our expert team knows exactly how to plan a project over time that will fit in your budget and give you the maximum ROI.`}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>Protect your wallet.</Typography>
                            <Typography variant={'h6'} sx={{ mt: 2 }}>
                                {`Lower maintenance costs`}
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`In some cases, landscaping is an afterthought for developers or builders who just want to get their project finished. Landscape elements that were not well thought out or executed may require more attention than a landscape that is well planned for your unique environment.`}
                            </Typography>
                            <Typography variant={'h6'} sx={{ mt: 2 }}>
                                {`Water conservation savings`}
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`Healthier plant material, better maintenance practices and more `}
                                <Link to={'/services/irrigation-management'}>efficient irrigation practices</Link>
                                {` can all add up to water savings - and budget savings. From lawn removal and water friendly plan designs to using advanced irrigation technology, our team provides a level of service and expertise that goes above and beyond.`}
                            </Typography>
                            <Typography variant={'h6'} sx={{ mt: 2 }}>
                                {`Increased property value`}
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`Well designed, water efficient landscaping is not only cost effective, it makes your property more desirable. `}
                                <Link to={'/news'}>Increasing the property value</Link>
                                {` of your community helps move any real estate faster and makes your residents happy!`}
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                <Link to={'/request-proposal?service=landscape-enhancements'}>Request a quote</Link>
                                {` for Landscape Enhancements.`}
                            </Typography>
                        </Box>
                    </Box>
                )}
                {selection === CTA_SLUGS.TreeCare && (
                    <Box id={CTA_SLUGS.TreeCare}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>Think of the trees.</Typography>
                            <Box sx={{ width: '100%', mt: 2, pb: '56.25%', position: 'relative' }}>
                                <img
                                    src={treeCare}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    alt={'A large flower garden growing atop a trellis.'}
                                />
                            </Box>
                            <Typography sx={{ mt: 2 }}>
                                {`We understand that your trees are not `}
                                <i>just</i>
                                {` trees. They are vital assets that increase the value of your property - but can also create major liabilities. Tree care should never be an afterthought to a landscape maintenance contract.`}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>Protect your tree assets.</Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`Our in-house Certified Arborist and expert team of tree care professionals are highly trained to consult and provide recommendations designed to improve and sustain tree health. We can also develop a detailed Tree Management Plan with every tree numbered and charted on a site map, alongside our recommendations for necessary care. These observations become the foundation of your annual tree maintenance every year.`}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                            <Typography variant={'h4'}>Limit tree liabilities.</Typography>
                            <Typography sx={{ mt: 2 }}>
                                {`Beyond protecting tree health, our team works to reduce insurance risks and ensure the safety of people, pets and property. Well maintained trees are much less likely to cause damage to structures, grow into sight lines or fall on utility cables, meaning our expert attention to your trees can save you money in the long run.`}
                            </Typography>
                            <Typography sx={{ mt: 2 }}>
                                <Link to={'/request-proposal?service=tree-work'}>Request a quote</Link>
                                {` for Tree Care services.`}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
