import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';
import { AppBar, Box, Button, ButtonProps, Drawer, DrawerProps, IconButton, SxProps, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import serpicoLogo from '../../assets/images/serpico-logo-white.png';
import useAuth from '../../hooks/useAuth';
import { SOCIAL_MEDIA } from '../../constants';

const NavLinkStyle: SxProps = {
    textDecoration: 'none',
    textTransform: 'none',
    fontSize: '1.2rem',
    textAlign: 'left',
    justifyContent: 'flex-start',
    fontWeight: 600,
    mt: 1,
    color: 'white',
};
const DrawerNavigationItem: React.FC<Pick<ButtonProps, 'sx'> & { to: string; onClose: () => void }> = (props) => {
    const { to, onClose, sx, children } = props;
    return (
        <Button
            // variant={'h6'}
            component={Link}
            to={to}
            sx={Object.assign({}, NavLinkStyle, sx)}
            onClick={onClose}
        >
            {children}
        </Button>
    );
};

export const NavigationDrawer: React.FC<DrawerProps & { onClose: () => void }> = (props) => {
    const { sx, ...otherDrawerProps } = props;
    const { isLoggedIn, logout } = useAuth();
    return (
        <Drawer
            anchor={'right'}
            {...otherDrawerProps}
            sx={Object.assign(
                {},
                {
                    '& > .MuiDrawer-paper': {
                        backgroundColor: 'primary.main',
                        backgroundImage: 'none',
                        color: 'white',
                        width: 400,
                        maxWidth: '90%',
                    },
                },
                sx
            )}
        >
            <AppBar position={'static'} sx={{ backgroundColor: 'transparent' }} elevation={0}>
                <Toolbar />
            </AppBar>
            <Box sx={{ p: { xs: 2, sm: 6 }, pt: 0, display: 'flex', flexDirection: 'column', flex: '1 1 0px' }}>
                <img
                    alt={'Serpico Logo'}
                    src={serpicoLogo}
                    width={'100%'}
                    height={'auto'}
                    style={{ maxWidth: 250, marginBottom: 48, marginLeft: 8 }}
                />

                <DrawerNavigationItem to={'/'} onClose={props.onClose}>
                    Home
                </DrawerNavigationItem>
                <DrawerNavigationItem to={'about'} onClose={props.onClose}>
                    About Us
                </DrawerNavigationItem>
                <DrawerNavigationItem to={'services'} onClose={props.onClose}>
                    Services
                </DrawerNavigationItem>
                <DrawerNavigationItem to={'news'} onClose={props.onClose}>
                    News
                </DrawerNavigationItem>
                <DrawerNavigationItem to={'mission'} onClose={props.onClose}>
                    Our Mission
                </DrawerNavigationItem>
                {!isLoggedIn && (
                    <DrawerNavigationItem to={'login'} onClose={props.onClose}>
                        Log In
                    </DrawerNavigationItem>
                )}
                {isLoggedIn && (
                    <DrawerNavigationItem to={'request-proposal'} onClose={props.onClose}>
                        Request Proposal
                    </DrawerNavigationItem>
                )}
                {isLoggedIn && (
                    <DrawerNavigationItem to={'profile'} onClose={props.onClose}>
                        Profile
                    </DrawerNavigationItem>
                )}
                {isLoggedIn && (
                    <Button
                        sx={NavLinkStyle}
                        onClick={() => {
                            logout();
                            props.onClose();
                        }}
                    >
                        Log Out
                    </Button>
                )}
                <Box sx={{ flex: '1 1 0px' }} />
                <Box sx={{ textAlign: 'center' }}>
                    <IconButton size={'large'} onClick={() => window.open(SOCIAL_MEDIA.FACEBOOK, '_blank')}>
                        <Facebook fontSize={'inherit'} />
                    </IconButton>
                    <IconButton size={'large'} onClick={() => window.open(SOCIAL_MEDIA.LINKEDIN, '_blank')}>
                        <LinkedIn fontSize={'inherit'} />
                    </IconButton>
                    <IconButton size={'large'} onClick={() => window.open(SOCIAL_MEDIA.INSTAGRAM, '_blank')}>
                        <Instagram fontSize={'inherit'} />
                    </IconButton>
                </Box>
            </Box>
        </Drawer>
    );
};
