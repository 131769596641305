import { Box, Typography } from '@mui/material';

export const PlantHealth: React.FC = () => (
    <>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Plant Health</Typography>
            <Box sx={{ width: '100%', mt: 2, pb: '56.25%', position: 'relative' }}>
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/Z_8Vv-_bSq8"
                    frameBorder={'0'}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0 }}
                />
            </Box>
            <Typography sx={{ mt: 2 }}>
                Resource Conservation: Pioneering Efficient Landscaping with Serpico
                <br /><br />
                Serpico is at the forefront of pioneering sustainable and efficient landscaping practices. Our integrated approach to preserving vital resources, while crafting stunning landscapes that blend seamlessly with the beauty of Northern California is like non other.
                <br /><br />
                Pioneering Sustainability in Landscaping
                <br />
                At Serpico, we firmly believe that a lush landscape does not need to come at the cost of our precious water resources. Through adept landscape management, we utilize industry-leading tools and methodologies to create magnificent gardens without wastage. Discover how our dedication to resource conservation translates into every project we undertake.
                <br /><br />
                Embracing Green Technology
                <br />
                In our continual effort to reduce our carbon footprint, we are integrating battery-powered equipment and hybrid trucks into our fleet. This move not only cuts down on pollution and fuel usage but also paves the way for a greener and cleaner future. Learn how our investments in green technology are reshaping the landscaping industry.
                <br /><br />
                A Team Built on Excellence and Efficiency
                <br />
                Our strength lies in our team - a group of high-quality employees driven by education and a passion for the environment. Witness how our trained professionals work tirelessly, armed with knowledge and technology, to ensure efficiency at every step of our landscaping processes.
            </Typography>
        </Box>
    </>
);
