import { useCallback, useEffect, useState } from 'react';
import { Box, Typography, SxProps, useTheme, useMediaQuery, Button, IconButton, Fade, Tooltip } from '@mui/material';
import { scroller, animateScroll } from 'react-scroll';

import technology from '../../assets/images/about/technology.jpeg';
import boardwalk from '../../assets/images/news/boardwalk.jpeg';
import montage from '../../assets/images/news/montage.jpeg';
import oasis from '../../assets/images/news/oasis.jpeg';
import treeService from '../../assets/images/news/tree-service.jpeg';
import booster from '../../assets/images/news/booster.png';
import montageCrew from '../../assets/images/news/montage-crew.jpeg';
import bush from '../../assets/images/news/bush.jpeg';
import salmonRose from '../../assets/images/news/SalmonRose.jpg';

import { CallToActionConfig, CallToActionGrid } from '../../components/navigation';
import { useNavigate, useParams } from 'react-router-dom';
import {
    BenefitsArticle,
    BoosterFuelsArticle,
    InvestingArticle,
    LastingPartnershipArticle,
    MindAndBodyArticle,
    OasisArticle,
    SerpicoCultureArticle,
    SmartIrrigationArticle,
    TechnologyArticle
} from './articles';
import { ArrowBack, ArrowForward, ArrowUpward } from '@mui/icons-material';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

type CTAKey =
    | 'smartIrrigation'
    | 'serpicoCulture'
    | 'lastingPartnership'
    | 'oasis'
    | 'benefits'
    | 'booster'
    | 'investing'
    | 'mindBody'
    | 'technology';

const CTA_SLUGS: { [key in CTAKey]: string } = {
    smartIrrigation: 'smart-irrigation-prop',
    serpicoCulture: 'serpico-culture',
    lastingPartnership: 'lasting-partnership',
    oasis: 'oasis-for-healing',
    benefits: '5-benefits-of-tree-service',
    booster: 'booster-fuels',
    investing: 'investing-in-landscaping',
    mindBody: 'mind-and-body',
    technology: 'technology'
};

const transparentBorder: SxProps = {
    borderWidth: 0,
    borderBottomWidth: 8,
    borderStyle: 'solid',
    borderColor: 'transparent',
};
const selected: SxProps = {
    borderWidth: 0,
    borderBottomWidth: 8,
    borderStyle: 'solid',
    borderColor: 'primary.main',
};

const handleScrollTo = (id: string) =>
    setTimeout(
        () =>
            scroller.scrollTo(id, {
                duration: 1000,
                delay: 0,
                smooth: true,
                offset: -100,
            }),
        100
    );

export const NewsPage: React.FC = () => {
    const { article: selection } = useParams();
    const [showScrollToTop, setShowScrollToTop] = useState(selection !== undefined);
    const navigate = useNavigate();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (selection && (sm || window.innerHeight < 900)) handleScrollTo(selection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useScrollPosition(
        ({ currPos }) => {
            setShowScrollToTop(currPos.y >= window.innerHeight);
        },
        [],
        undefined,
        true,
        200
    );

    const getPageDetails = useCallback(
        (props: { label: string; slug: string; image: string }): CallToActionConfig => {
            const { image, slug, label } = props;
            return {
                src: image,
                title: label,
                props: {
                    onClick: () => {
                        if (selection === slug) {
                            navigate('/news');
                        } else {
                            navigate(`/news/${slug}`);
                            if (sm || window.innerHeight < 900) handleScrollTo(slug);
                        }
                    },
                    sx: selection === slug ? selected : transparentBorder,
                },
            };
        },
        [selection, sm, navigate]
    );

    const nextArticle = useCallback(
        (step: 1 | -1) => {
            const keys = Object.keys(CTA_SLUGS);
            let nextSlug: string = selection || '';
            if (!selection) {
                nextSlug = CTA_SLUGS[keys[0] as CTAKey];
                navigate(`/news/${nextSlug}`);
            } else {
                const currentIndex = keys.findIndex((key) => CTA_SLUGS[key as CTAKey] === selection);
                const nextIndex = (currentIndex + keys.length + step) % keys.length;
                nextSlug = CTA_SLUGS[keys[nextIndex] as CTAKey];
                navigate(`/news/${nextSlug}`);
            }
            handleScrollTo(nextSlug);
        },
        [navigate, selection]
    );

    return (
        <Box sx={{ maxWidth: 1200, m: '0 auto', mt: { xs: 7, sm: 8 }, p: { xs: 2, sm: 8 } }}>
            <Typography variant={'h3'} sx={{ fontWeight: 700 }}>
                Check out what's new in our world
            </Typography>

            <Box sx={{ width: '100%', m: '0 auto', maxWidth: { xs: 400, sm: 'unset' } }}>
                <CallToActionGrid
                    sx={{ mt: 6 }}
                    items={[
                        getPageDetails({
                            slug: CTA_SLUGS.smartIrrigation,
                            label: 'Best Practices From a Smart Irrigation Pro',
                            image: technology,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.serpicoCulture,
                            label: 'Giving is a Circle: Serpico Culture',
                            image: boardwalk,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.lastingPartnership,
                            label: 'A Lasting Partnership: Customer Highlight',
                            image: montage,
                        }),
                        getPageDetails({ slug: CTA_SLUGS.oasis, label: 'A Magical Oasis for Healing', image: oasis }),
                        getPageDetails({
                            slug: CTA_SLUGS.benefits,
                            label: '5 Benefits of Professional Tree Service',
                            image: treeService,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.booster,
                            label: 'Recapture Time with Booster Fuels',
                            image: booster,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.investing,
                            label: 'Why Invest in Your HOA Landscaping?',
                            image: montageCrew,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.mindBody,
                            label: 'Landscapes are Healthy for Mind and Body',
                            image: bush,
                        }),
                        getPageDetails({
                            slug: CTA_SLUGS.technology,
                            label: 'Harnessing Technology to Revolutionize Landscape Services at Serpico',
                            image: salmonRose
                        })
                    ]}
                />
                {selection === CTA_SLUGS.smartIrrigation && (
                    <Box id={CTA_SLUGS.smartIrrigation}>
                        <SmartIrrigationArticle />
                    </Box>
                )}
                {selection === CTA_SLUGS.serpicoCulture && (
                    <Box id={CTA_SLUGS.serpicoCulture}>
                        <SerpicoCultureArticle />
                    </Box>
                )}
                {selection === CTA_SLUGS.lastingPartnership && (
                    <Box id={CTA_SLUGS.lastingPartnership}>
                        <LastingPartnershipArticle />
                    </Box>
                )}
                {selection === CTA_SLUGS.oasis && (
                    <Box id={CTA_SLUGS.oasis}>
                        <OasisArticle />
                    </Box>
                )}
                {selection === CTA_SLUGS.benefits && (
                    <Box id={CTA_SLUGS.benefits}>
                        <BenefitsArticle />
                    </Box>
                )}
                {selection === CTA_SLUGS.booster && (
                    <Box id={CTA_SLUGS.booster}>
                        <BoosterFuelsArticle />
                    </Box>
                )}
                {selection === CTA_SLUGS.investing && (
                    <Box id={CTA_SLUGS.investing}>
                        <InvestingArticle />
                    </Box>
                )}
                {selection === CTA_SLUGS.mindBody && (
                    <Box id={CTA_SLUGS.mindBody}>
                        <MindAndBodyArticle />
                    </Box>
                )}
                {selection === CTA_SLUGS.technology && (
                    <Box id={CTA_SLUGS.technology}>
                        <TechnologyArticle />
                    </Box>
                )}
            </Box>

            {selection && (
                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                    <Button startIcon={<ArrowBack />} onClick={() => nextArticle(-1)}>
                        Previous Article
                    </Button>
                    <Button endIcon={<ArrowForward />} onClick={() => nextArticle(1)}>
                        Next Article
                    </Button>
                </Box>
            )}
            <Fade in={showScrollToTop}>
                <Tooltip title={'Back to top'}>
                    <IconButton
                        onClick={() => {
                            animateScroll.scrollToTop({
                                duration: 1000,
                                delay: 0,
                                smooth: true,
                            });
                        }}
                        sx={{ position: 'fixed', right: 16, top: '50%', transform: 'translateY(-50%)' }}
                    >
                        <ArrowUpward />
                    </IconButton>
                </Tooltip>
            </Fade>
        </Box>
    );
};
