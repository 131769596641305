import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
// import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { App } from './App';
// import { store } from './store';
import './index.css';
import '@fontsource/work-sans';
import '@fontsource/work-sans/500.css';
import '@fontsource/work-sans/600.css';
import '@fontsource/work-sans/700.css';
import '@fontsource/work-sans/800.css';
import '@fontsource/work-sans/900.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { ScrollToTop } from './components/ScrollToTop';

ReactDOM.render(
    // <Provider store={store}>
    <BrowserRouter>
        <ScrollToTop />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <App />
        </LocalizationProvider>
    </BrowserRouter>,
    // </Provider>
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
