import { APP_PREFIX } from '../../constants';

type ActionType = {
    REQUEST: string;
    SUCCESS: string;
    FAILURE: string;
};
export const createAction = (action: string): ActionType => ({
    REQUEST: `${APP_PREFIX}/${action}.REQUEST`,
    SUCCESS: `${APP_PREFIX}/${action}.SUCCESS`,
    FAILURE: `${APP_PREFIX}/${action}.FAILURE`,
});
