import { Box, SxProps } from '@mui/material';

const barStyle: SxProps = {
    width: 24,
    height: 4,
    // mt: 0.5,
    // mb: 0.5,
    backgroundColor: 'primary.main',
    transition: 'all 300ms linear',
    position: 'absolute',
    left: 0,
};

export const AnimatedHamburger: React.FC<{ isMenu?: boolean }> = (props) => {
    const { isMenu } = props;
    return (
        <Box sx={{ position: 'relative', height: 20, width: 24 }}>
            <Box
                sx={{
                    ...barStyle,
                    ...(isMenu ? { top: 0 } : { top: 8, transform: 'rotate(45deg)', backgroundColor: 'white' }),
                }}
            />
            <Box sx={{ ...barStyle, ...(isMenu ? { top: 8 } : { top: 8, opacity: 0, backgroundColor: 'white' }) }} />
            <Box
                sx={{
                    ...barStyle,
                    ...(isMenu ? { top: 16 } : { top: 8, transform: 'rotate(-45deg)', backgroundColor: 'white' }),
                }}
            />
        </Box>
        //     <div (click) = "drawer.toggle()" class="btn" >
        //         <div class="icon-bar" [@hamburguerX]="isHamburguer ? 'hamburguer' : 'topX'" ></div>
        //             <div class="icon-bar" [@hamburguerX]="isHamburguer ? 'hamburguer' : 'hide'" ></div>
        //                 <div class="icon-bar" [@hamburguerX]="isHamburguer ? 'hamburguer' : 'bottomX'" ></div>
        //   </div >
    );
};
