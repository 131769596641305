import {
    Box,
    Button,
    Typography,
    TextField,
    FormHelperText,
    Grid,
    MenuItem,
    Collapse,
    TextFieldProps,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useScriptRef from '../../hooks/useScriptRef';
import { ExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import { UserAccount } from '../../types/account';
import useQuery from '../../hooks/useQuery';

type UserWithExtras = Omit<UserAccount, 'uid' | 'token'> & { password: string; submit: any };
const initialData: UserWithExtras = {
    firstName: '',
    lastName: '',
    roles: '',
    email: '',
    password: '',
    propertyName: '',
    numUnits: 0,
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    marketing: false,
    submit: null,
};

const inputProps: TextFieldProps = {
    variant: 'outlined',
    // size: 'small',
    // margin: 'none',
    fullWidth: true,
};

export const RegisterPage: React.FC = (props) => {
    const query = useQuery();
    const scriptedRef = useScriptRef();
    const { firebaseEmailPasswordNewUser, firebaseCheckAccountExists } = useAuth();
    const [showExtraFields, setShowExtraFields] = useState(false);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: 1200,
                minHeight: '100vh',
                m: '0 auto',
                p: { xs: 2, sm: 8 },
            }}
        >
            <Typography variant={'h4'} sx={{ textAlign: 'center' }}>
                Sign up to join our team!
            </Typography>

            <Box sx={{ maxWidth: 600, m: { xs: 2, sm: 8 } }}>
                <Formik
                    initialValues={{ ...initialData, roles: query.get('role') || '' }}
                    validationSchema={Yup.object().shape({
                        roles: Yup.string().required('Role is required'),
                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        password: Yup.string()
                            .min(6, 'Your password must be at least 6 characters')
                            .max(255)
                            .required('Password is required'),
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        const userDetails: UserAccount = { ...values, uid: '', token: '' } as UserAccount;
                        // @ts-ignore
                        delete userDetails.submit;
                        // @ts-ignore
                        delete userDetails.password;
                        try {
                            await firebaseEmailPasswordNewUser(values.email, values.password, { ...userDetails });
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                        } catch (err: any) {
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        setFieldError,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => {
                        return (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id={'first-name'}
                                            name={'firstName'}
                                            label={'First Name'}
                                            {...inputProps}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id={'last-name'}
                                            name={'lastName'}
                                            label={'Last Name'}
                                            {...inputProps}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id={'roles'}
                                            name={'roles'}
                                            label={'Your Role'}
                                            {...inputProps}
                                            select
                                            value={values.roles}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={touched.roles && Boolean(errors.roles)}
                                            helperText={touched.roles && errors.roles}
                                        >
                                            <MenuItem value={'community-manager'}>Community Manager</MenuItem>
                                            <MenuItem value={'facilities-manager'}>Facilities Manager</MenuItem>
                                            <MenuItem value={'hoa-board-member'}>HOA Board Member</MenuItem>
                                            <MenuItem value={'landscape-committee-member'}>
                                                Landscape Committee Member
                                            </MenuItem>
                                            <MenuItem value={'other'}>Other</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id={'email-address'}
                                            name={'email'}
                                            label={'Email Address'}
                                            {...inputProps}
                                            onBlur={async (e) => {
                                                handleBlur(e);
                                                if (await firebaseCheckAccountExists(values.email)) {
                                                    setFieldError('email', 'Email Address is already registered');
                                                }
                                            }}
                                            onChange={handleChange}
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id={'password'}
                                            name={'password'}
                                            label={'Password'}
                                            type={'password'}
                                            {...inputProps}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={touched.password && Boolean(errors.password)}
                                            helperText={touched.password && errors.password}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            fullWidth
                                            color={'inherit'}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                textAlign: 'left',
                                                cursor: 'pointer',
                                                pl: 1.5,
                                                pr: 1.5,
                                                textTransform: 'none',
                                            }}
                                            onClick={() => setShowExtraFields((show) => !show)}
                                        >
                                            <Typography sx={{ flex: '1' }}>{`${
                                                showExtraFields ? 'Hide' : 'Add More'
                                            } Details`}</Typography>
                                            <ExpandMore
                                                sx={{
                                                    transition: 'transform 300ms ease-in-out',
                                                    transform: `rotate(${showExtraFields ? 180 : 0}deg)`,
                                                }}
                                            />
                                        </Button>
                                        <Collapse in={showExtraFields} sx={{ mt: showExtraFields ? 2 : 0 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={9}>
                                                    <TextField
                                                        id={'property-name'}
                                                        name={'propertyName'}
                                                        label={'Property Name'}
                                                        {...inputProps}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        id={'number-units'}
                                                        name={'numUnits'}
                                                        label={'# of Units'}
                                                        {...inputProps}
                                                        type={'number'}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id={'address1'}
                                                        name={'address1'}
                                                        label={'Address Line 1'}
                                                        {...inputProps}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id={'address2'}
                                                        name={'address2'}
                                                        label={'Address Line 2'}
                                                        {...inputProps}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={5}>
                                                    <TextField
                                                        id={'city'}
                                                        name={'city'}
                                                        label={'City'}
                                                        {...inputProps}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <TextField
                                                        id={'state'}
                                                        name={'state'}
                                                        label={'State'}
                                                        {...inputProps}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        id={'xip'}
                                                        name={'zip'}
                                                        label={'Zip Code'}
                                                        {...inputProps}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            sx={{ mt: 2 }}
                                            control={
                                                <Checkbox
                                                    checked={values.marketing}
                                                    onChange={(e) => {
                                                        setFieldValue('marketing', e.target.checked);
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography variant={'body2'}>
                                                    {'Sign me up for '}
                                                    <Typography
                                                        component={'span'}
                                                        color={'primary.light'}
                                                        sx={{ font: 'inherit', display: 'inline', fontWeight: 600 }}
                                                    >
                                                        GoSerpico
                                                    </Typography>
                                                    {' news and marketing materials!'}
                                                </Typography>
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        {errors.submit && (
                                            <Box sx={{ mb: 2 }}>
                                                <FormHelperText error>{errors.submit}</FormHelperText>
                                            </Box>
                                        )}
                                        <Button
                                            fullWidth
                                            type={'submit'}
                                            variant={'contained'}
                                            color={'primary'}
                                            sx={{ mt: 2 }}
                                            disabled={
                                                isSubmitting ||
                                                Boolean(errors.email) ||
                                                Boolean(errors.password) ||
                                                Boolean(errors.roles) ||
                                                values.password.length < 1 ||
                                                values.email.length < 1 ||
                                                values.roles.length < 1
                                            }
                                        >
                                            Sign Up
                                        </Button>
                                    </Grid>
                                    {/* <Typography
                                        component={Link}
                                        to={'forgot-password'}
                                        sx={{
                                            mt: 4,
                                            display: 'block',
                                            fontWeight: 600,
                                            textAlign: 'center',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        Forgot Password?
                                    </Typography> */}
                                    <Grid item xs={12}>
                                        <Typography sx={{ mt: 2, textAlign: 'center' }}>
                                            Already have an account?{' '}
                                            <Typography
                                                component={Link}
                                                to={'/login'}
                                                sx={{ fontWeight: 600, textDecoration: 'none' }}
                                            >
                                                Log In
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
};
