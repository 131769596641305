// Firebase Utilities
import { addDoc, collection, doc, getFirestore, setDoc, getDocs } from 'firebase/firestore';
import { /*getDownloadURL,*/ getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { UserAccount } from '../types/account';
import { Candidate } from '../types/jobs';
import { ProposalRequest } from '../types/proposal';
import { Opportunity } from '../types/opportunity';

const storage = getStorage();
const db = getFirestore();

export const firebaseUploadFile = (params: {
    category: string;
    file: File;
    onProgress: (percent: number) => void;
    onSuccess: (downloadURL: string) => void;
    onError: (e: any) => void;
}) => {
    const { category, file, onProgress, onSuccess, onError } = params;
    const imageId = `${category}_${Math.random().toString(36).substring(2)}`;
    const storageRef = ref(storage, imageId);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
        'state_changed',
        (snapshot) => {
            onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        },
        (error) => {
            onError(error);
        },
        async () => {
            // const url = await getDownloadURL(uploadTask.snapshot.ref);
            onSuccess(imageId);
        }
    );
};
export const firebaseAddCandidate = (candidate: Candidate, documentId: string) => {
    const attachment = candidate.type === 'office' ? { resume: documentId } : { application: documentId };
    return addDoc(collection(db, 'candidates'), { ...candidate, ...attachment });
};

export const firebaseAddProposalRequest = (proposal: ProposalRequest & { userID: string }, documentId?: string) => {
    const attachment = documentId ? { mapFile: documentId } : { mapFile: '' };
    return addDoc(collection(db, 'proposals'), { ...proposal, ...attachment, submittedDate: new Date(Date.now()) });
};

export const firebaseUpdateUserProfile = (user: UserAccount) => {
    return setDoc(doc(db, 'user', user.token), user);
};

export const firebaseGetOpportunities = async (): Promise<Opportunity[]> => {
    const opportunities: Opportunity[] = [];
    const querySnapshot = await getDocs(collection(db, 'opportunities'));
    querySnapshot.forEach((doc) => {
        const data = doc.data();
        opportunities.push({
            id: doc.id,
            name: data.name,
            description: data.description,
            closedDate: data.closedDate.toDate(),
        });
    });
    return opportunities;
};
