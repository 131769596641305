import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Footer, Header } from '.';

export const SharedNavigationContainer: React.FC = () => (
    <>
        <Header />
        <Box /*sx={{ mt: { xs: 7, sm: 8 } }}*/>
            <Outlet />
        </Box>
        <Footer />
    </>
);
