import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { usePrevious } from '../hooks/usePrevious';

export const ScrollToTop = (): any => {
    const { pathname, hash } = useLocation();
    const previousPath = usePrevious(pathname);

    const basePathChanged = !previousPath || pathname.split('/')[1] !== previousPath?.split('/')[1];

    useEffect(() => {
        // if an anchor link is present, scroll to the anchor link;
        // else scroll the page to the top
        if (hash) {
            const id = hash.replace('#', '');
            const headline = document.getElementById(id);
            if (headline) {
                window.scrollTo(0, headline.offsetTop);
            } else {
                window.scrollTo(0, 0);
            }
        } else if (basePathChanged) {
            window.scrollTo(0, 0);
        }
    }, [pathname, basePathChanged, hash]);

    return null;
};
