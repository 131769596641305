import { Box, Typography } from '@mui/material';

export const Mission: React.FC = () => (
    <>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Serpico’s Mission </Typography>
            <Box sx={{ width: '100%', mt: 2, pb: '56.25%', position: 'relative' }}>
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/iCsMPUze-PE"
                    frameBorder={'0'}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0 }}
                />
            </Box>
            <Typography sx={{ mt: 2 }}>
                {`At Serpico, we believe that landscaping is an art form that can transform any outdoor space into a work of beauty. As Northern California's premier landscaping company, it is our mission to provide our clients with the highest quality landscape work possible.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Our CEO, Peter Novak, is committed to delivering the best possible service to our clients. We have worked with a diverse range of clients, including HOAs, multi-unit buildings, common interest developments, corporate business parks, educational locations, and hospitals. With our broad range of experience, our team is well-equipped to handle any project, regardless of its size or complexity.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`One of our main goals is to provide our clients with the best technology available in the industry. We believe that staying up to date with the latest landscaping technology is essential to delivering the best possible service. By utilizing cutting-edge tools and equipment, our team of professionals can deliver projects more efficiently and to a higher standard than our competitors.
At Serpico, we place a strong emphasis on customer service. Our team is committed to ensuring that every client is satisfied with the work we provide. From the initial consultation to the final walk-through, our professionals are there to answer any questions and ensure that the project runs smoothly.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`In addition to our commitment to exceptional service, we also prioritize sustainability in our landscaping practices. We understand the importance of using eco-friendly materials and techniques to protect the environment. By implementing sustainable practices, we can provide our clients with beautiful landscapes that are also environmentally responsible.
In conclusion, Serpico is the premier choice for anyone in Northern California looking for exceptional landscaping services. With our dedication to excellence, use of cutting-edge technology, commitment to customer service, and emphasis on sustainability, we are confident that we can deliver the highest quality work to all of our clients.`}
            </Typography>
        </Box>
    </>
);
