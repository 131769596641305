import { useMemo } from 'react';
import { Box, BoxProps, SxProps, Typography, useTheme } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { DropzoneOptions, DropzoneRootProps } from 'react-dropzone/typings/react-dropzone';
import Color from 'color';

type FileUploadProps = BoxProps &
    DropzoneRootProps & {
        label?: string;
        dropZoneOptions?: DropzoneOptions;
    };
export const FileUploadWithDragDrop: React.FC<FileUploadProps> = (props) => {
    const { dropZoneOptions, label, ...rootProps } = props;
    const { style: rootStyle, ...otherRootProps } = rootProps;
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone(dropZoneOptions);
    const theme = useTheme();

    const baseStyle: SxProps = {
        flex: 1,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 1,
        borderColor: Color(theme.palette.primary.light).alpha(0.5).toString(),
        borderStyle: 'dashed',
        backgroundColor: 'transparent',
        color: 'text.disabled',
        outline: 'none',
        transition: `border ${theme.transitions.duration.standard} ${theme.transitions.easing.easeInOut}`,
    };

    const activeStyle: SxProps = {
        backgroundColor: 'background.paper',
        borderColor: 'text.primary',
    };

    const acceptStyle: SxProps = {
        borderColor: 'primary.main',
    };

    const rejectStyle: SxProps = {
        borderColor: 'error.main',
    };

    const sx = useMemo(
        (): SxProps => ({
            ...rootStyle,
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
            /* eslint-disable react-hooks/exhaustive-deps */
        }),
        [rootStyle, isDragActive, isDragReject, isDragAccept]
    );
    /* eslint-enable react-hooks/exhaustive-deps */

    return (
        <Box {...getRootProps({ sx, ...otherRootProps })}>
            <input {...getInputProps()} />
            {label && (
                <Typography variant={'h6'} color={isDragAccept ? 'primary' : isDragReject ? 'error' : 'inherit'}>
                    {label}:
                </Typography>
            )}
            {isDragAccept ? (
                <Typography variant={'body2'} color={'primary'}>
                    Drop to Add!
                </Typography>
            ) : isDragReject ? (
                <Typography variant={'body2'} color={'error'}>
                    Invalid File Type
                </Typography>
            ) : (
                <Typography
                    variant={'body2'}
                    color={'inherit'}
                >{`Drag & drop file here, or click to browse`}</Typography>
            )}
        </Box>
    );
};
