import { Box, Typography, Button, Card, CardContent } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { firebaseGetOpportunities } from '../firebase';
import { Opportunity } from '../types/opportunity';

export const OpportunitiesPage: React.FC = () => {
    const [opportunities, setOpportunities] = useState<Opportunity[]>([]);

    useEffect(() => {
        const fetchOpportunities = async () => {
            const opportunities = await firebaseGetOpportunities();
            setOpportunities(opportunities);
        };
        fetchOpportunities();
    }, []);

    return (
        <Box sx={{ maxWidth: 1200, m: '0 auto', mt: { xs: 7, sm: 8 }, p: { xs: 2, sm: 8 } }}>
            <Typography variant={'h3'} sx={{ fontWeight: 700 }}>
                Career Opportunities
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
                <Typography variant={'subtitle1'} sx={{}}>
                    {`We don't do typical. Do you have what it takes to `}
                    <Typography
                        component={'span'}
                        color={'primary.light'}
                        sx={{ font: 'inherit', display: 'inline', fontWeight: 600 }}
                    >
                        GoSerpico
                    </Typography>
                    {'?'}
                </Typography>

                <Box sx={{ mt: 4 }}>
                    <Typography variant={'h6'}>{`Driven by innovation.`}</Typography>
                    <Typography sx={{ mt: 2 }}>
                        {`Serpico is not your typical landscape company. We are driven by innovation, powered by technology, and committed to our core values:`}
                    </Typography>
                    <Box sx={{ mt: 4, mb: 2 }}>
                        <Typography variant={'h6'} sx={{ mt: 2, textAlign: 'center' }}>
                            {`Safety`}
                        </Typography>
                        <Typography variant={'h6'} sx={{ mt: 1, textAlign: 'center' }}>
                            {`Quality`}
                        </Typography>
                        <Typography variant={'h6'} sx={{ mt: 1, textAlign: 'center' }}>
                            {`Integrity`}
                        </Typography>
                        <Typography variant={'h6'} sx={{ mt: 1, textAlign: 'center' }}>
                            {`Respect & Trust`}
                        </Typography>
                        <Typography variant={'h6'} sx={{ mt: 1, textAlign: 'center' }}>
                            {`Resource Conservation`}
                        </Typography>
                        <Typography variant={'h6'} sx={{ mt: 1, textAlign: 'center' }}>
                            {`Innovation`}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Typography variant={'h6'}>{`Rigorous training.`}</Typography>
                    <Typography sx={{ mt: 2 }}>
                        {`All employees are trained with our rigorous Serpico Standards. Maintaining these standards ensures that no matter where you're starting from, we'll all be on the same page with our horticulture and water management practices, equipment, safety and communications.`}
                    </Typography>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Typography variant={'h6'}>{`Nature lovers.`}</Typography>
                    <Typography sx={{ mt: 2 }}>
                        {`Our employees come from all different industries and experience levels, but they share one thing: a love for the outdoors. As landscapers, we have the unique opportunity to create spaces that improve our customers' lives while also respecting and protecting the environment.`}
                    </Typography>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Typography variant={'h6'}>{`Strategic locations.`}</Typography>
                    <Typography sx={{ mt: 2 }}>
                        {`Our regional locations are strategically located so we can serve clients throughout Northern California in the most efficient manner. We plan our crews' daily routes to minimize time on the road. We also utilize an innovative fuel delivery system that eliminates gas station stops.`}
                    </Typography>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Typography variant={'h6'}>{`Open Positions.`}</Typography>
                    <Box sx={{ mt: 2 }}>
                        {opportunities.map((opportunity) => (
                            <Card key={opportunity.id} sx={{ mt: 2, cursor: 'pointer' }}>
                                <CardContent component={Link} to={`/job-application/${opportunity.id}`}>
                                    <Typography variant={'h6'}>{opportunity.name}</Typography>
                                    <Typography variant={'subtitle1'}>{opportunity.description}</Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                </Box>
                <Box sx={{ mt: 4, alignSelf: 'center', color: 'primary.contrastText' }}>
                    <Button variant={'contained'} component={Link} to={'/job-application'}>
                        Apply Now
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
