import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';
import { Box, Typography, useMediaQuery, TypographyProps, IconButton, SxProps } from '@mui/material';
import { Link } from 'react-router-dom';
import serpicoLogo from '../../assets/images/serpico-logo-white.png';
import { SOCIAL_MEDIA } from '../../constants';

const FooterLink: React.FC<Pick<TypographyProps, 'sx'> & { to?: string; href?: string }> = (props) => {
    const { to, href, children, sx } = props;
    const sharedStyles: SxProps = {
        color: 'white',
        textDecoration: 'none',
        mt: 2,
    };
    const linkStyles: SxProps = {
        '&:hover': {
            color: 'primary.light',
        },
    };
    return to ? (
        <Typography component={Link} to={to} sx={Object.assign({}, sharedStyles, linkStyles, sx)}>
            {children}
        </Typography>
    ) : href ? (
        <Typography component={'a'} href={href} sx={Object.assign({}, sharedStyles, linkStyles, sx)}>
            {children}
        </Typography>
    ) : (
        <Typography sx={Object.assign({}, sharedStyles, sx)}>{children}</Typography>
    );
};

export const Footer: React.FC = () => {
    const width750 = useMediaQuery('(max-width: 749px)');

    return (
        <>
            <Box sx={{ backgroundColor: 'primary.main', height: 24 }} />
            <Box
                sx={{
                    backgroundColor: '#0f0f0f',
                    p: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img
                    alt={'Serpico Logo'}
                    src={serpicoLogo}
                    width={'100%'}
                    height={'auto'}
                    style={{ maxWidth: 250, marginBottom: 16 }}
                />
                <Typography sx={{ textAlign: 'center', mb: 6 }}>
                    Raising the bar for Northern California Commercial Landscaping{' '}
                </Typography>
                <Typography variant={'h6'} sx={{ textAlign: 'center', mb: 6, textTransform: 'uppercase', fontWeight: 600 }}>
                    CSLB License #588595{' '}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: width750 ? 'column' : 'row',
                        alignItems: width750 ? 'center' : 'flex-start',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: width750 ? 'column' : 'row',
                            alignItems: width750 ? 'center' : 'flex-start',
                            textAlign: width750 ? 'center' : 'left',
                            justifyContent: 'center',
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant={'h6'} sx={{ mb: 2, textTransform: 'uppercase', fontWeight: 600 }}>
                                Contact Us
                            </Typography>
                            <FooterLink>
                                1764 National Ave.
                                <br />
                                Hayward, CA 94545
                            </FooterLink>
                            <FooterLink href={'tel:18662820341'}>1.866.282.0341</FooterLink>
                            <FooterLink href={'mailto:mailbox@goserpico.com'}>mailbox@goserpico.com</FooterLink>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mt: 2,
                                    justifyContent: width750 ? 'center' : 'flex-start',
                                }}
                            >
                                <IconButton
                                    color={'primary'}
                                    edge={width750 ? undefined : 'start'}
                                    onClick={() => window.open(SOCIAL_MEDIA.FACEBOOK, '_blank')}
                                >
                                    <Facebook sx={{ fontSize: 36 }} />
                                </IconButton>
                                <IconButton
                                    color={'primary'}
                                    onClick={() => window.open(SOCIAL_MEDIA.INSTAGRAM, '_blank')}
                                >
                                    <Instagram sx={{ fontSize: 36 }} />
                                </IconButton>
                                <IconButton
                                    color={'primary'}
                                    onClick={() => window.open(SOCIAL_MEDIA.LINKEDIN, '_blank')}
                                >
                                    <LinkedIn sx={{ fontSize: 36 }} />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box sx={{ flex: '0 0 auto', height: 64, width: 48, mt: { xs: 4, sm: 0 } }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant={'h6'} sx={{ mb: 2, textTransform: 'uppercase', fontWeight: 600 }}>
                                Work With Us
                            </Typography>
                            <FooterLink to={'/request-proposal'}>Request a Proposal</FooterLink>
                            <FooterLink to={'/opportunities'}>Career Opportunities</FooterLink>
                        </Box>
                        <Box sx={{ flex: '0 0 auto', height: 64, width: 48, mt: { xs: 4, sm: 0 } }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant={'h6'} sx={{ mb: 2, textTransform: 'uppercase', fontWeight: 600 }}>
                                Learn More
                            </Typography>
                            <FooterLink to={'/about'}>About Us</FooterLink>
                            <FooterLink to={'/services'}>Services</FooterLink>
                            <FooterLink to={'/news'}>News</FooterLink>
                            <FooterLink to={'/privacy-policy'}>Privacy Policy</FooterLink>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: 'primary.main', height: 24 }} />
        </>
    );
};
