import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { GuardProps } from '../../types';

const AuthGuard = ({ children }: GuardProps) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login', { replace: true, state: { from: location } });
        }
    }, [isLoggedIn, navigate, location]);

    return children;
};

export default AuthGuard;
