import { Navigate, useRoutes } from 'react-router-dom';
import { SharedNavigationContainer } from '../../components/navigation/SharedNavigation';
import {
    AboutPage,
    HomePage,
    LoginPage,
    RegisterPage,
    PrivacyPolicyPage,
    ServicesPage,
    OpportunitiesPage,
    JobApplicationPage,
    RequestProposalPage,
    ForgotPasswordPage,
    ProfilePage,
    NewsPage,
    ResetPasswordPage,
    ChangePasswordPage,
    MissionPage
} from '../../views';
import AuthGuard from '../route-guards/AuthGuard';
import GuestGuard from '../route-guards/GuestGuard';

const Routes = (): React.ReactElement<any, string | React.JSXElementConstructor<any>> | null =>
    useRoutes([
        {
            path: '/',
            element: <SharedNavigationContainer />,
            children: [
                {
                    path: '',
                    element: <HomePage />,
                },
                {
                    path: '/about',
                    children: [
                        {
                            path: '/about',
                            element: <AboutPage />,
                        },
                        {
                            path: '/about/:feature',
                            element: <AboutPage />,
                        },
                    ],
                },
                {
                    path: '/services',
                    children: [
                        {
                            path: '/services',
                            element: <ServicesPage />,
                        },
                        {
                            path: '/services/:service',
                            element: <ServicesPage />,
                        },
                    ],
                },
                {
                    path: '/news',
                    children: [
                        {
                            path: '/news',
                            element: <NewsPage />,
                        },
                        {
                            path: '/news/:article',
                            element: <NewsPage />,
                        },
                    ],
                },
                {
                    path: '/mission',
                    children: [
                        {
                            path: '/mission',
                            element: <MissionPage />,
                        },
                        {
                            path: '/mission/:missions',
                            element: <MissionPage />,
                        },
                    ],
                },

                {
                    path: '/request-proposal',
                    element: (
                        <AuthGuard>
                            <RequestProposalPage />
                        </AuthGuard>
                    ),
                },
                {
                    path: '/privacy-policy',
                    element: <PrivacyPolicyPage />,
                },
                {
                    path: '/opportunities',
                    element: <OpportunitiesPage />,
                },
                {
                    path: '/job-application',
                    element: <JobApplicationPage />,
                },

                {
                    path: '/login',
                    element: (
                        <GuestGuard>
                            <LoginPage />
                        </GuestGuard>
                    ),
                },
                {
                    path: '/register',
                    element: (
                        <GuestGuard>
                            <RegisterPage />
                        </GuestGuard>
                    ),
                },
                {
                    path: '/forgot-password',
                    element: (
                        <GuestGuard>
                            <ForgotPasswordPage />
                        </GuestGuard>
                    ),
                },
                {
                    path: '/reset-password',
                    element: (
                        <GuestGuard>
                            <ResetPasswordPage />
                        </GuestGuard>
                    ),
                },
                {
                    path: '/change-password',
                    element: (
                        <AuthGuard>
                            <ChangePasswordPage />
                        </AuthGuard>
                    ),
                },
                {
                    path: '/profile',
                    element: (
                        <AuthGuard>
                            <ProfilePage />
                        </AuthGuard>
                    ),
                },
                {
                    path: '*',
                    element: <Navigate to={'/'} />,
                },
            ],
        },
    ]);
export default Routes;
