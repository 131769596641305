import { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    TextField,
    FormHelperText,
    Grid,
    MenuItem,
    TextFieldProps,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import useAuth from '../../hooks/useAuth';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useScriptRef from '../../hooks/useScriptRef';
import { UserAccount } from '../../types/account';
import { firebaseUpdateUserProfile } from '../../firebase';
import { FIREBASE_STATE_CHANGED } from '../../store/actions';
import { Link } from 'react-router-dom';

type UserWithExtras = UserAccount & { password: string; submit: any };

const inputProps: TextFieldProps = {
    variant: 'outlined',
    // size: 'small',
    // margin: 'none',
    fullWidth: true,
};

export const ProfilePage: React.FC = (props) => {
    const { user } = useAuth();
    // const query = useQuery();
    const scriptedRef = useScriptRef();
    const { dispatch, firebaseChangeCurrentUserEmail, firebaseReAuthenticate, firebaseCheckAccountExists } = useAuth();
    // const [showExtraFields, setShowExtraFields] = useState(false);
    const [showReauth, setShowReauth] = useState(false);
    const [reauthError, setReauthError] = useState('');

    const initialData: UserWithExtras = {
        firstName: '',
        lastName: '',
        roles: '',
        email: '',
        password: '',
        propertyName: '',
        numUnits: 0,
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        uid: '',
        token: '',
        marketing: false,
        submit: null,
        ...user,
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: 1200,
                minHeight: '100vh',
                m: '0 auto',
                p: { xs: 2, sm: 8 },
            }}
        >
            <Typography variant={'h4'} sx={{ textAlign: 'center' }}>
                Update your information
            </Typography>

            <Box sx={{ maxWidth: 600, m: { xs: 2, sm: 8 } }}>
                <Formik
                    initialValues={initialData}
                    enableReinitialize
                    validationSchema={Yup.object().shape({
                        roles: Yup.string().required('Role is required'),
                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        ...(showReauth
                            ? { password: Yup.string().required('Your password is required to update your settings') }
                            : {}),
                    })}
                    onSubmit={async (values, { setErrors, setFieldError, setStatus, setSubmitting, resetForm }) => {
                        if (values.email !== initialData.email && (await firebaseCheckAccountExists(values.email))) {
                            setFieldError('email', 'Email Address is already registered');
                            setStatus({ success: false });
                            setSubmitting(false);
                            return;
                        }
                        const userDetails: UserAccount = { ...values } as UserAccount;
                        // @ts-ignore
                        delete userDetails.submit;
                        // @ts-ignore
                        delete userDetails.password;
                        try {
                            // try the email first to see if we need to re-authenticate
                            if (userDetails.email !== initialData.email) {
                                await firebaseChangeCurrentUserEmail(userDetails.email);
                            }
                            await firebaseUpdateUserProfile(userDetails);
                            dispatch({
                                type: FIREBASE_STATE_CHANGED,
                                payload: {
                                    isLoggedIn: true,
                                    user: userDetails as UserAccount,
                                },
                            });
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                        } catch (err: any) {
                            // If user needs to reauthenticate
                            if (err.code === 'auth/requires-recent-login') {
                                // Prompt for new credentials
                                setShowReauth(true);
                            }
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        setFieldError,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => {
                        return (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id={'first-name'}
                                            name={'firstName'}
                                            label={'First Name'}
                                            value={values.firstName}
                                            {...inputProps}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id={'last-name'}
                                            name={'lastName'}
                                            label={'Last Name'}
                                            value={values.lastName}
                                            {...inputProps}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id={'roles'}
                                            name={'roles'}
                                            label={'Your Landscaping Role'}
                                            {...inputProps}
                                            select
                                            value={values.roles}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={touched.roles && Boolean(errors.roles)}
                                            helperText={touched.roles && errors.roles}
                                        >
                                            <MenuItem value={'community-manager'}>Community Manager</MenuItem>
                                            <MenuItem value={'facilities-manager'}>Facilities Manager</MenuItem>
                                            <MenuItem value={'hoa-board-member'}>HOA Board Member</MenuItem>
                                            <MenuItem value={'landscape-committee-member'}>
                                                Landscape Committee Member
                                            </MenuItem>
                                            <MenuItem value={'other'}>Other</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id={'email-address'}
                                            name={'email'}
                                            label={'Email Address'}
                                            // disabled
                                            value={values.email}
                                            {...inputProps}
                                            // onBlur={handleBlur}
                                            onBlur={async (e) => {
                                                handleBlur(e);
                                                if (values.email === initialData.email) return;
                                                if (await firebaseCheckAccountExists(values.email)) {
                                                    setFieldError('email', 'Email Address is already registered');
                                                }
                                            }}
                                            onChange={handleChange}
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email}
                                        />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            id={'property-name'}
                                            name={'propertyName'}
                                            label={'Property Name'}
                                            value={values.propertyName}
                                            {...inputProps}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id={'number-units'}
                                            name={'numUnits'}
                                            label={'# of Units'}
                                            value={values.numUnits}
                                            {...inputProps}
                                            type={'number'}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id={'address1'}
                                            name={'address1'}
                                            label={'Address Line 1'}
                                            value={values.address1}
                                            {...inputProps}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id={'address2'}
                                            name={'address2'}
                                            label={'Address Line 2'}
                                            value={values.address2}
                                            {...inputProps}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <TextField
                                            id={'city'}
                                            name={'city'}
                                            label={'City'}
                                            value={values.city}
                                            {...inputProps}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            id={'state'}
                                            name={'state'}
                                            label={'State'}
                                            value={values.state}
                                            {...inputProps}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            id={'xip'}
                                            name={'zip'}
                                            label={'Zip Code'}
                                            value={values.zip}
                                            {...inputProps}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        sx={{ mt: 2 }}
                                        control={
                                            <Checkbox
                                                checked={values.marketing}
                                                onChange={(e) => {
                                                    setFieldValue('marketing', e.target.checked);
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography variant={'body2'}>
                                                {'I want to receive '}
                                                <Typography
                                                    component={'span'}
                                                    color={'primary.light'}
                                                    sx={{ font: 'inherit', display: 'inline', fontWeight: 600 }}
                                                >
                                                    GoSerpico
                                                </Typography>
                                                {' news and marketing materials!'}
                                            </Typography>
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    {errors.submit && (
                                        <Box sx={{ mb: 2 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                    <Button
                                        fullWidth
                                        type={'submit'}
                                        variant={'contained'}
                                        color={'primary'}
                                        sx={{ mt: 2 }}
                                        disabled={
                                            isSubmitting ||
                                            (values.firstName === initialData.firstName &&
                                                values.lastName === initialData.lastName &&
                                                values.roles === initialData.roles &&
                                                values.email === initialData.email &&
                                                values.propertyName === initialData.propertyName &&
                                                values.numUnits === initialData.numUnits &&
                                                values.address1 === initialData.address1 &&
                                                values.address2 === initialData.address2 &&
                                                values.city === initialData.city &&
                                                values.state === initialData.state &&
                                                values.zip === initialData.zip &&
                                                values.marketing === initialData.marketing) ||
                                            Boolean(errors.email) ||
                                            (showReauth && values.password.length < 1)
                                        }
                                    >
                                        Save Changes
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ mt: 2, textAlign: 'center' }}>
                                        Want to change your password?{' '}
                                        <Typography
                                            component={Link}
                                            to={'/change-password'}
                                            sx={{ fontWeight: 600, textDecoration: 'none' }}
                                        >
                                            Change Password
                                        </Typography>
                                    </Typography>
                                </Grid>

                                <Dialog open={showReauth} PaperProps={{ sx: { maxWidth: 500 } }}>
                                    <DialogTitle>Password Required</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            It's been a while since you logged in. Your password is required to change
                                            the email address used for your account.
                                        </DialogContentText>
                                        <TextField
                                            id={'password'}
                                            name={'password'}
                                            label={'Password'}
                                            type={'password'}
                                            value={values.password}
                                            sx={{ mt: 4 }}
                                            {...inputProps}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={Boolean(reauthError)}
                                            helperText={Boolean(reauthError) && reauthError}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button sx={{ mr: 2 }} onClick={() => setShowReauth(false)}>
                                            Cancel
                                        </Button>
                                        <Button
                                            variant={'contained'}
                                            disabled={values.password.length < 1}
                                            onClick={() => {
                                                firebaseReAuthenticate(values.password)
                                                    .then(() => {
                                                        setShowReauth(false);
                                                        // @ts-ignore
                                                        handleSubmit({ values });
                                                    })
                                                    .catch((e) => {
                                                        setReauthError('Error — check your password and try again');
                                                        setFieldValue('password', '');
                                                    });
                                            }}
                                        >
                                            Confirm
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
};
