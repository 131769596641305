import { createAction } from './utilities';

export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';

// Account Actions
export const LOGIN = createAction('LOGIN');
export const LOGOUT = createAction('LOGOUT');
export const ACCOUNT_INITIALIZE = createAction('ACCOUNT_INITIALIZE');
export const CREATE_ACCOUNT = createAction('CREATE_ACCOUNT');
export const CLEAR_AUTH_ERROR = createAction('CLEAR_AUTH_ERROR');

// User Actions
export const UPDATE_USER = createAction('UPDATE_USER');

// profile
export const GET_PROFILE = createAction('GET_PROFILE');
export const UPDATE_PROFILE = createAction('UPDATE_PROFILE');

// productions
export const GET_PRODUCTIONS = createAction('GET_PRODUCTIONS');
export const GET_PRODUCTION = createAction('GET_PRODUCTION');

// callsheets
export const GET_CALLSHEETS = createAction('GET_CALLSHEETS');
export const GET_CALLSHEET = createAction('GET_CALLSHEET');
export const GET_LATEST_CALLSHEET = createAction('GET_LATEST_CALLSHEET');
export const UNSET_LATEST_CALLSHEET = createAction('UNSET_LATEST_CALLSHEET');

// productionReports
export const UNSET_LATEST_PRODUCTION_REPORT = createAction('UNSET_LATEST_PRODUCTION_REPORT');

// lunch
export const GET_LUNCH_OPTIONS = createAction('GET_LUNCH_OPTIONS');
export const GET_LUNCH_SELECTION = createAction('GET_LUNCH_SELECTION');
export const MAKE_LUNCH_SELECTION = createAction('MAKE_LUNCH_SELECTION');
export const UPDATE_LUNCH_SELECTION = createAction('UPDATE_LUNCH_SELECTION');

// Covid
export const GET_COVID_CHECK_STATUS = createAction('GET_COVID_CHECK_STATUS');
export const ADD_COVID_STATUS_CHECK = createAction('ADD_COVID_STATUS_CHECK');
