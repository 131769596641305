import { Box, Button, Typography, TextField, FormHelperText } from '@mui/material';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useScriptRef from '../../hooks/useScriptRef';

const SuccessBox: React.FC = () => (
    <Box sx={{ textAlign: 'center' }}>
        <Typography variant={'h6'} color={'primary'}>
            SUCCESS
        </Typography>
        <Typography>Your request has been received —</Typography>
        <Typography>check your email for instructions!</Typography>
        <Box sx={{ color: 'primary.contrastText' }}>
            <Button fullWidth variant={'contained'} component={Link} to={'/login'} sx={{ mt: 2, minWidth: 100 }}>
                Log In
            </Button>
        </Box>
    </Box>
);

export const ForgotPasswordPage: React.FC = (props) => {
    const scriptedRef = useScriptRef();
    const { firebaseSendPasswordResetEmail } = useAuth();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: 1200,
                minHeight: '100vh',
                m: '0 auto',
                p: { xs: 2, sm: 8 },
            }}
        >
            <Box sx={{ maxWidth: 600, m: { xs: 2, sm: 8 } }}>
                <Formik
                    initialValues={{
                        email: '',
                        submit: null,
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            await firebaseSendPasswordResetEmail(values.email);
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                        } catch (err: any) {
                            console.error(err);
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, status }) => {
                        return status?.success ? (
                            <SuccessBox />
                        ) : (
                            <form noValidate onSubmit={handleSubmit}>
                                <Typography variant={'h4'} sx={{ textAlign: 'center' }}>
                                    Forgot your password?
                                </Typography>
                                <Typography sx={{ mt: 1, mb: { xs: 2, sm: 8 }, textAlign: 'center' }}>
                                    Enter the email address you used to sign up and we'll send you a link to reset your
                                    password.
                                </Typography>
                                <TextField
                                    id={'email-address'}
                                    name={'email'}
                                    label={'Email Address'}
                                    variant={'outlined'}
                                    fullWidth
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                                {errors.submit && (
                                    <Box sx={{ mt: 2 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                )}
                                <Button
                                    fullWidth
                                    type={'submit'}
                                    variant={'contained'}
                                    color={'primary'}
                                    sx={{ mt: 2 }}
                                    disabled={isSubmitting || Boolean(errors.email) || values.email.length < 1}
                                >
                                    Send Instructions
                                </Button>
                                <Typography sx={{ mt: 4, textAlign: 'center' }}>
                                    Remembered your password?{' '}
                                    <Typography
                                        component={Link}
                                        to={'/login'}
                                        sx={{ fontWeight: 600, textDecoration: 'none' }}
                                    >
                                        Log In
                                    </Typography>
                                </Typography>
                            </form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
};
