import {
    Box,
    Button,
    Typography,
    TextField,
    FormHelperText,
    Grid,
    TextFieldProps,
    ToggleButton,
    ToggleButtonGroup,
    IconButton,
    CircularProgress,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useScriptRef from '../hooks/useScriptRef';
import { AttachFile, Clear } from '@mui/icons-material';
import { useState } from 'react';
import { FileUploadWithDragDrop } from '../components/inputs/FileUpload';
import { firebaseAddCandidate, firebaseUploadFile } from '../firebase';
import { Candidate } from '../types/jobs';
import { Link } from 'react-router-dom';

const initialData: Candidate & { submit?: any } = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    type: 'office',
    submit: null,
};

const inputProps: TextFieldProps = {
    variant: 'outlined',
    fullWidth: true,
};

const SuccessBox: React.FC = () => (
    <Box sx={{ textAlign: 'center' }}>
        <Typography variant={'h6'} color={'primary'}>
            SUCCESS
        </Typography>
        <Typography>Your application has been received —</Typography>
        <Typography>we'll be in touch soon!</Typography>
        <Box sx={{ color: 'primary.contrastText' }}>
            <Button fullWidth variant={'contained'} component={Link} to={'/'} sx={{ mt: 2, minWidth: 100 }}>
                Home
            </Button>
        </Box>
    </Box>
);

export const JobApplicationPage: React.FC = (props) => {
    const scriptedRef = useScriptRef();
    const [attachment, setAttachment] = useState<File | null>(null);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: 1200,
                minHeight: '100vh',
                m: '0 auto',
                p: { xs: 2, sm: 8 },
            }}
        >
            <Box sx={{ maxWidth: 600, width: '100%', m: { xs: 2, sm: 8 } }}>
                <Formik
                    initialValues={{ ...initialData }}
                    validationSchema={Yup.object().shape({
                        firstName: Yup.string().required('Required'),
                        lastName: Yup.string().required('Required'),
                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    })}
                    onSubmit={(values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                        try {
                            if (!attachment) return;
                            const candidate = { ...values };
                            delete candidate.submit;

                            firebaseUploadFile({
                                category: `CANDIDATE_${values.type === 'office' ? 'RESUME' : 'APPLICATION_FORM'}`,
                                file: attachment,
                                onProgress: (percent) => {
                                    /*console.log(percent)*/
                                },
                                onSuccess: (docId) => {
                                    firebaseAddCandidate(candidate, docId).then((res) => {
                                        resetForm();
                                        setAttachment(null);
                                        if (scriptedRef.current) {
                                            setStatus({ success: true });
                                            setSubmitting(false);
                                        }
                                    });
                                },
                                onError: (err) => {
                                    if (scriptedRef.current) {
                                        setStatus({ success: false });
                                        setErrors({ submit: err.message });
                                        setSubmitting(false);
                                    }
                                },
                            });
                        } catch (err: any) {
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        status,
                    }) => {
                        return status?.success ? (
                            <SuccessBox />
                        ) : (
                            <form noValidate onSubmit={handleSubmit}>
                                <Typography variant={'h4'} sx={{ textAlign: 'center', mb: { xs: 2, sm: 8 } }}>
                                    Job Application
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: { xs: 'column', sm: 'row' },
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Typography>I want to work in the:</Typography>
                                            <ToggleButtonGroup
                                                sx={{ mt: { xs: 2, sm: 0 } }}
                                                color="primary"
                                                value={values.type}
                                                exclusive
                                                onChange={(e, val) => {
                                                    if (val === null) return;
                                                    if (val !== values.type) setAttachment(null);
                                                    setFieldValue('type', val);
                                                }}
                                            >
                                                <ToggleButton value="office">Office</ToggleButton>
                                                <ToggleButton value="field">Field</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Box>
                                    </Grid>
                                    {values.type === 'field' && (
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                color={'primary'}
                                                onClick={() =>
                                                    window.open('/1A-EMPLOYMENT%20APPLICATION.pdf', '_blank')
                                                }
                                                sx={{ textAlign: 'center' }}
                                            >
                                                Download an application form
                                            </Button>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id={'first-name'}
                                            name={'firstName'}
                                            label={'First Name'}
                                            {...inputProps}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id={'last-name'}
                                            name={'lastName'}
                                            label={'Last Name'}
                                            {...inputProps}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id={'email-address'}
                                            name={'email'}
                                            label={'Email Address'}
                                            {...inputProps}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id={'phone-number'}
                                            name={'phone'}
                                            label={'Phone Number'}
                                            {...inputProps}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    {!attachment && (
                                        <Grid item xs={12}>
                                            <FileUploadWithDragDrop
                                                label={`Upload your ${
                                                    values.type === 'office' ? 'Resume' : 'Application Form'
                                                }`}
                                                dropZoneOptions={{
                                                    accept: 'application/pdf, .pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/html, text/plain, application/vnd.oasis.opendocument.text',
                                                    maxFiles: 1,
                                                    multiple: false,
                                                    onDropAccepted: (files, e) => {
                                                        setAttachment(files[0]);
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    )}
                                    {attachment && (
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <AttachFile color={'primary'} />
                                                <Typography
                                                    color={'primary'}
                                                    sx={{
                                                        ml: 1,
                                                        flex: '1 1 0px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {attachment.name}
                                                </Typography>
                                                <IconButton color={'primary'} onClick={() => setAttachment(null)}>
                                                    <Clear />
                                                </IconButton>
                                            </Box>
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        {errors.submit && (
                                            <Box sx={{ mb: 2 }}>
                                                <FormHelperText error>{errors.submit}</FormHelperText>
                                            </Box>
                                        )}
                                        <Button
                                            fullWidth
                                            type={'submit'}
                                            variant={'contained'}
                                            color={'primary'}
                                            sx={{ mt: 2 }}
                                            disabled={
                                                isSubmitting ||
                                                Boolean(errors.email) ||
                                                Boolean(errors.firstName) ||
                                                Boolean(errors.lastName) ||
                                                values.firstName.length < 1 ||
                                                values.email.length < 1 ||
                                                values.lastName.length < 1 ||
                                                !attachment
                                            }
                                        >
                                            {!isSubmitting ? (
                                                'Submit'
                                            ) : (
                                                <CircularProgress color={'inherit'} size={36} />
                                            )}
                                        </Button>
                                        {status?.success && <Typography color={'primary'}>Success!</Typography>}
                                    </Grid>
                                </Grid>
                            </form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
};
