import { ExpandMore } from '@mui/icons-material';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { scroller } from 'react-scroll';
import serpicoLogo from '../assets/images/serpico-logo-white.png';
import { useEffect } from 'react';

interface VideoBannerProps {
    onLoaded?: () => void;
}

const handleScrollDown = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    scroller.scrollTo('scroll-to-me', {
        duration: 1000,
        delay: 0,
        smooth: true,
        offset: -100,
    });
};

export const VideoBanner: React.FC<VideoBannerProps> = ({ onLoaded }) => {
    const maxAspect = useMediaQuery('(max-aspect-ratio: 16/9)');
    const minAspect = useMediaQuery('(min-aspect-ratio: 16/9)');
    const minHeight = useMediaQuery('(max-height: 400px)');

    useEffect(() => {
        // Assuming the video will autoplay as soon as it's loaded
        if (typeof onLoaded === 'function') {
            onLoaded();
        }
    }, [onLoaded]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'hidden',
                height: '100vh',
                width: '100vw',
            }}
        >
            <iframe
                title={'serpico-video-banner'}
                style={{
                    position: 'absolute',
                    zIndex: -1,
                    top: '50%',
                    left: '50%',
                    opacity: 0.7,
                    height: minAspect ? '56.25vw' : '100vh',
                    width: maxAspect ? '177.78vh' : '100vw',
                    cursor: 'pointer',
                    transform: 'translate(-50%, -50%)',
                }}
                src="https://player.vimeo.com/video/949717534?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;background=1"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
            ></iframe>
            <Box
                sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    flex: '1 1 0px',
                    width: '100%',
                    p: { xs: 2, sm: 3 },
                    mt: { xs: 7, sm: 8 },
                }}
                onClick={handleScrollDown}
            >
                <img
                    alt={'Serpico Logo'}
                    src={serpicoLogo}
                    width={'100%'}
                    height={'auto'}
                    style={{ maxWidth: 350, marginBottom: 16 }}
                />
                <Typography variant={minHeight ? 'h6' : 'h4'}>Your Northern California Commercial Landscaping Partner</Typography>
            </Box>

            <Box
                sx={{
                    mb: 2,
                    flex: '0 0 auto',
                    textAlign: 'center',
                    color: 'white',
                }}
            >
                <Typography>Learn More</Typography>
                <IconButton onClick={handleScrollDown} size={'small'}>
                    <ExpandMore />
                </IconButton>
            </Box>
        </Box>
    );
};
