import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import technology from '../../../assets/images/about/technology.jpeg';

export const SmartIrrigationArticle: React.FC = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
        <Typography variant={'h4'}>Best Practices From a Smart Irrigation Pro</Typography>
        <img
            src={technology}
            style={{ width: '100%', height: 'auto', marginTop: 16 }}
            alt={'A man pushing a lawn mower.'}
        />
        <Typography sx={{ mt: 2 }}>
            {`Is a smart controller the right fit for my property? If so, which smart controller is the best? The answers to these questions can be complicated.`}
        </Typography>
        <Typography sx={{ mt: 2 }}>
            {`In short, the answer depends on your property and who will be managing the controller. Our expertise with weather-based irrigation controllers started clear back in 2005 and our team has tested and been trained on many different makes and models. With this depth of experience, we are able to recommend the best controller for your specific needs, as well as provide an irrigation team that knows how to maximize results.`}
        </Typography>
        <Typography
            component={'blockquote'}
            sx={{
                m: 4,
                ml: 'auto',
                mr: 'auto',
                maxWidth: 600,
                pl: 2,
                borderLeft: '8px solid white',
                borderLeftColor: 'primary.main',
            }}
        >
            {`"It's really important that if you have a smart controller, you also have a smart person, someone who's trained, who knows how to make the most of it."`}
            <b>{` — Peter Novak, Serpico President`}</b>
        </Typography>
        <Typography sx={{ mt: 2 }}>
            {`Serpico President Peter Novak shares more about how smart controllers can streamline operations and important details to consider before implementing water management technology. Read the complete article in `}
            <a
                href={
                    'https://www.landscapemanagement.net/one-irrigation-contractor-shares-his-experience-with-smart-controllers/'
                }
            >
                Landscape Management Magazine
            </a>
            {` and learn more about our `}
            <Link to={'/services/irrigation-management'}>Irrigation Management</Link>
            {` Services here.`}
        </Typography>
    </Box>
);
