import { Box, Button, IconButton, Typography, useMediaQuery, CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import yellowFlower from '../assets/images/nature/yellow-flower-hi-res.jpg';
import purpleFlower from '../assets/images/nature/purple-flower-hi-res.jpg';
import redFlower from '../assets/images/nature/red-flower-hi-res.jpg';
import grass from '../assets/images/nature/grass-hi-res.jpg';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { VideoBanner } from '../components/VideoBanner';
import { CallToActionGrid } from '../components/navigation';
import useAuth from '../hooks/useAuth';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

export const HomePage: React.FC = (props) => {
    const { isLoggedIn, user } = useAuth();
    const width600 = useMediaQuery('(max-width: 599px)');
    const width800 = useMediaQuery('(max-width: 799px)');

    const [videoLoaded, setVideoLoaded] = useState(false);  // <-- Fixed here
    const [playingSound, setPlayingSound] = useState(false); // <-- Fixed here

    const handleVideoLoaded = () => {
        // const audio = new Audio('/serpico.mp3'); // Correct this path
        // audio.play().then(() => {
        //     setPlayingSound(true);
        //     setTimeout(() => setPlayingSound(false), (audio.duration * 1000));
        // });
        setVideoLoaded(true);
    };

    return (
        <>
            {/* Video Banner */}
            {!videoLoaded && <CircularProgress />} 
            <VideoBanner onLoaded={handleVideoLoaded} />

            {/* Role Cards */}
            {!isLoggedIn && (
                <Box
                    id={'scroll-to-me'}
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: { xs: 2, sm: 8 } }}
                >
                    <Typography variant={'h4'} sx={{ textAlign: 'center' }}>
                        I am a...
                    </Typography>
                    <Typography variant={'subtitle1'} sx={{ textAlign: 'center' }}>
                        Select your role to create a GoSerpico account
                    </Typography>

                    {/* Call To Action Image Grid */}
                    <CallToActionGrid
                        sx={{ width: '100%', height: 'auto', m: 0, mt: 6, maxWidth: 1200 }}
                        items={[
                            {
                                src: yellowFlower,
                                to: 'register?role=community-manager',
                                title: 'Community Manager',
                            },
                            {
                                src: purpleFlower,
                                to: 'register?role=facilities-manager',
                                title: 'Facilities Manager',
                            },
                            {
                                src: redFlower,
                                to: 'register?role=hoa-board-member',
                                title: 'HOA Board Member',
                            },
                            {
                                src: grass,
                                to: 'register?role=landscape-committee-member',
                                title: 'Landscape Committee Member',
                            },
                        ]}
                    />
                </Box>
            )}

            {/* Welcome Message */}
            {isLoggedIn && (
                <Box
                    id={'scroll-to-me'}
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: { xs: 2, sm: 8 } }}
                >
                    <Typography variant={'h4'} sx={{ textAlign: 'center' }}>
                        {`Welcome back${user?.firstName ? ` ${user.firstName}` : ''}!`}
                    </Typography>
                    <Typography variant={'subtitle1'} sx={{ textAlign: 'center' }}>
                        {'Are you ready to '}
                        <Typography
                            component={'span'}
                            color={'primary.light'}
                            sx={{ font: 'inherit', display: 'inline', fontWeight: 600 }}
                        >
                            GoSerpico
                        </Typography>
                        {'?'}
                    </Typography>
                </Box>
            )}

            {/* Request Proposal Button */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: { xs: 2, sm: 8 },
                    color: 'primary.contrastText',
                }}
            >
                <Button variant={'contained'} component={Link} to={'request-proposal'} color={'primary'}>
                    Request a Proposal
                </Button>
            </Box>

            {/* Testimonial Carousel */}
            <Box sx={{ p: { xs: 2, sm: 8 }, pb: { xs: 4, sm: 16 } }}>
                <Carousel
                    autoPlay
                    infiniteLoop
                    showIndicators={false}
                    showStatus={false}
                    showThumbs={false}
                    stopOnHover={false}
                    swipeable={false}
                    interval={10000}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                            <IconButton
                                onClick={onClickHandler}
                                sx={{
                                    position: 'absolute',
                                    left: 0,
                                    zIndex: 2,
                                    top: 'calc(50% - 20px)',
                                }}
                            >
                                <ArrowBack />
                            </IconButton>
                        )
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                            <IconButton
                                onClick={onClickHandler}
                                sx={{
                                    position: 'absolute',
                                    right: 0,
                                    zIndex: 2,
                                    top: 'calc(50% - 20px)',
                                }}
                            >
                                <ArrowForward />
                            </IconButton>
                        )
                    }
                    children={[
                        `If you are looking to enhance the value of your community, Serpico will work collaboratively to exceed your expectations, all within a realistic budget.`,
                        `Our community looks so much better since Serpico took over the landscaping — we have truly found a great landscaping partner!`,
                        `Can't say enough about the professional care Serpico takes in maintaining the challenging landscape at Altamar at the Ridge.`,
                        `The community has never looked better and with Serpico at the helm we expect even more improvements for our residents.`,
                        `The Serpico Team is always very fast in responding to my emails and takes the time and effort to handle our requests smoothly. Your Team always goes above and beyond to help keep our HOA looking great!`,
                    ].map((item, index) => (
                        <Typography
                            key={index}
                            variant={width600 ? 'h6' : width800 ? 'h5' : 'h4'}
                            sx={{ textAlign: 'center', ml: 6, mr: 6 }}
                        >{`"${item}"`}</Typography>
                    ))}
                />
            </Box>
        </>
    );
};
