import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import treeService from '../../../assets/images/news/tree-service.jpeg';

export const BenefitsArticle: React.FC = () => (
    <>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>5 Benefits of Professional Tree Service</Typography>
            <Box sx={{ width: '100%', mt: 2, pb: '56.25%', position: 'relative' }}>
                <img
                    src={treeService}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                    alt={'A worker trimming trees from a cherry picker'}
                />
            </Box>
            <Typography sx={{ mt: 2 }}>
                {`If you've known the comfort of a mature tree shading your home or the tragedy of a diseased tree falling on it, you understand the value in taking good care of urban trees. Our trees can offer so much if we care for them and they can damage so much if we ignore them.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Having a professional care for the trees in your neighborhood has numerous benefits. Below are 5 top benefits of advanced tree care.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>1. Increased value</Typography>
            <Typography sx={{ mt: 2 }}>
                {`Picture a shady, tree-lined street and a street lined with newly planted saplings. The difference is immediately clear! Healthy trees grow in value as they mature. Trees that are diseased, girdled, topped, or otherwise damaged do not have the same long-term value as strong, healthy trees. Unfortunately these issues are all common with sub-par tree care.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>2. Reduced insurance risks</Typography>
            <Typography sx={{ mt: 2 }}>
                {`In addition to increasing value, healthy trees are less likely to break or fall, causing damage to property, people and pets. For an HOA, the insurance costs associated with tree damage in a common area greatly outweigh the expense of regular tree maintenance.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Although many homeowners prefer to perform tree maintenance on their own properties, this is very dangerous. Professionals have extensive training and the right equipment for safety and performance.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>3. Optimal arbor care practices</Typography>
            <Typography sx={{ mt: 2 }}>
                {`Certified Arborists and experienced professional technicians are trained to make precise cuts to maintain a tree's health and the safety of the crew and property. All shaping and reducing cuts should be done utilizing TCIA and ANSI A300 industry standards for arbor care. The proper cut will help your tree thrive and look better over time.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Tree care professionals may also mulch removed tree material to be used in other landscape areas like natural open spaces or drainage ditches. This usage gives the mulch a beneficial second life mitigating weeds or runoff instead of being delivered to a landfill.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>4. Reduced watering and waste</Typography>
            <Typography sx={{ mt: 2 }}>
                {`In California, one of the most important benefits of professional tree service is reducing water waste. Typical tree watering is shallow, which causes much of the irrigation water to evaporate and encourages shallow roots. Shallow roots, in turn, make a tree more prone to falling and more susceptible to drought.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`A tree care professional should practice focused, deep root watering. This method eliminates evaporation by delivering water directly to the root system, protecting the tree and our precious water resources at the same time.`}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>5. Health tracking and improvements</Typography>
            <Typography sx={{ mt: 2 }}>
                {`An experienced arbor care professional knows your trees. With a detailed tree management plan, your tree service partner can track their condition and what they need to thrive, which helps ensure that their labor is effective.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`Working with a tree care professional, you should see the health and value of your trees thrive over time while liabilities and water waste are reduced. Trees are growing assets and they deserve careful attention and personal care.`}
            </Typography>
            <Typography sx={{ mt: 2 }}>
                {`If your community needs professional tree care services, `}
                <Link to={'/request-proposal?service=tree-work'}>Request a Proposal</Link>
                {` to find out how Serpico can work with your long-term budget.`}
            </Typography>
        </Box>
    </>
);
