import { Box, Typography } from '@mui/material';

export const Sustainability: React.FC = () => (
    <>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 6 }}>
            <Typography variant={'h4'}>Sustainability</Typography>
            <Box sx={{ width: '100%', mt: 2, pb: '56.25%', position: 'relative' }}>
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/CLZO5nU9rVE"
                    frameBorder={'0'}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0 }}
                />
            </Box>
            <Typography sx={{ mt: 2 }}>
                Plant Health: The Foundation of a Beautiful Landscape | Serpico Landscaping
                <br /><br />
                A picturesque environment begins with nurturing the plants at its core, ensuring they receive the ideal balance of nutrients to flourish beautifully.
                <br /><br />
                Nurturing Your Greens with Serpico
                <br />
                At Serpico, we believe in nurturing every plant to its fullest potential. Our experts spend dedicated time to understand the unique needs of each plant species in your garden, ensuring they get the proper nourishment, care, and attention to showcase their best selves.
                <br /><br />
                Why Plant Health Matters
                <br />
                A vibrant garden is an orchestra of well-tuned elements, with each plant playing a crucial role. Prioritizing plant health can transform your outdoor space into a picturesque retreat. Serpico's expertise can make this vision a reality for you.
            </Typography>
        </Box>
    </>
);
