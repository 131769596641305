import { createTheme } from '@mui/material';
import Color from 'color';

const basicTheme = createTheme({
    typography: {
        fontFamily: 'Work Sans, Roboto, Helvetica, Arial, sans-serif',
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#8dc63f',
        },
        secondary: {
            main: '#8aad5a',
        },
        background: {
            default: '#0f0f0f',
            paper: '#000000',
        },
    },
});

export const SerpicoTheme = createTheme(
    {
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '&:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: basicTheme.palette.primary.main,
                        },
                        input: {
                            '&:-webkit-autofill': {
                                WebkitBoxShadow: '0 0 0 100px #1b270c inset',
                                WebkitTextFillColor: '#fff',
                            },
                        },
                    },
                    notchedOutline: {
                        borderColor: Color(basicTheme.palette.primary.light).alpha(0.5).toString(),
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        padding: 10,
                        fontSize: '1rem',
                        fontWeight: 600,
                    },
                    text: {
                        padding: 10,
                        fontSize: '1rem',
                        fontWeight: 600,
                    },
                    outlined: {
                        padding: 10,
                        fontSize: '1rem',
                        fontWeight: 600,
                    },
                },
            },
        },
    },
    basicTheme
);
