import React, { createContext, useCallback, useEffect, useReducer } from 'react';

import firebase from 'firebase/compat/app';
import {
    confirmPasswordReset,
    EmailAuthProvider,
    getAuth,
    reauthenticateWithCredential,
    updateEmail,
    updatePassword,
    verifyPasswordResetCode,
} from 'firebase/auth';
import { getFirestore, collection, getDocs, query, where, DocumentData, addDoc, doc, setDoc } from 'firebase/firestore';
import 'firebase/compat/auth';

// action - state management
import { FIREBASE_STATE_CHANGED } from '../store/actions';

// project imports
import { FIREBASE_CONFIG } from '../constants';
import { InitialAuthContextProps } from '../types';
import { Loader } from '../components/Loader';
import { UserAccount } from '../types/account';

// firebase initialize
if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_CONFIG);
}
const db = getFirestore();
const auth = getAuth();

// reducer - state management
const reducer = (
    state = initialState,
    action: { type: string; payload: { isLoggedIn: boolean; user: UserAccount | null } }
) => {
    switch (action.type) {
        case FIREBASE_STATE_CHANGED: {
            const { isLoggedIn, user } = action.payload;
            return {
                ...state,
                isLoggedIn,
                isInitialized: true,
                user,
            };
        }
        default: {
            return { ...state };
        }
    }
};

// const
const initialState: InitialAuthContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    loading: false,
    user: null,
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext({
    ...initialState,
    firebaseEmailPasswordNewUser: (email: string, password: string, user: UserAccount) => {},
    firebaseEmailPasswordSignIn: (email: string, password: string) => {},
    firebaseGoogleSignIn: () => {},
    firebaseCheckAccountExists: (email: string) => Promise.resolve(false),
    firebaseSendPasswordResetEmail: (email: string) => {},
    firebaseChangeCurrentUserEmail: (newEmail: string) => Promise.resolve(),
    firebaseVerifyPasswordResetCode: (code: string) => {},
    firebaseResetPassword: (code: string, newPassword: string) => {},
    firebaseUpdatePassword: (oldPassword: string, newPassword: string) => Promise.resolve(),
    firebaseReAuthenticate: (password: string) => Promise.resolve(),
    dispatch: (config: { type: string; payload: any }) => {},
    logout: () => {},
});

export const FirebaseProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const firebaseEmailPasswordSignIn = (email: string, password: string) =>
        firebase.auth().signInWithEmailAndPassword(email, password);

    const firebaseGoogleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        return firebase.auth().signInWithPopup(provider);
    };

    const firebaseEmailPasswordNewUser = async (
        email: string,
        password: string,
        user: Omit<UserAccount, 'uid' | 'token'>
    ) =>
        firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(async (credential) => {
                const uid = credential.user?.uid;
                // create a new user document
                const docRef = await addDoc(collection(db, 'user'), user);
                const details = { ...user, uid, token: docRef.id };
                // update the user with a reference to its document id
                await setDoc(doc(db, 'user', docRef.id), details);
                handleAuthChange({ uid });
            });

    const firebaseCheckAccountExists = async (email: string) => {
        const q = query(collection(db, 'user'), where('email', '==', email));
        const userDocs = await getDocs(q);
        return userDocs.docs.length > 0;
    };

    const firebaseSendPasswordResetEmail = async (email: string) => firebase.auth().sendPasswordResetEmail(email);

    const firebaseVerifyPasswordResetCode = (code: string) => {
        return verifyPasswordResetCode(auth, code);
    };

    const firebaseResetPassword = (code: string, newPassword: string) => {
        return confirmPasswordReset(auth, code, newPassword);
    };

    const firebaseUpdatePassword = (oldPassword: string, newPassword: string) => {
        return firebaseReAuthenticate(oldPassword)?.then(() => {
            if (!auth.currentUser) {
                logout();
                Promise.reject('User is not logged in');
            } else {
                updatePassword(auth.currentUser, newPassword);
            }
        });
    };

    const firebaseChangeCurrentUserEmail = (newEmail: string): Promise<void> => {
        if (auth.currentUser) {
            return updateEmail(auth.currentUser, newEmail);
        }
        return Promise.reject('User is not logged in');
    };

    const firebaseReAuthenticate = (password: string) => {
        if (!auth.currentUser) {
            logout();
            Promise.reject('User is not logged in');
        } else {
            const credential = EmailAuthProvider.credential(auth.currentUser?.email || '', password);
            return reauthenticateWithCredential(auth.currentUser, credential);
        }
    };

    const logout = () => firebase.auth().signOut();

    const handleAuthChange = useCallback(
        async (user: firebase.User | { uid?: string } | null) => {
            if (user) {
                const q = query(collection(db, 'user'), where('uid', '==', user.uid));
                const userDocs = await getDocs(q);
                if (userDocs.docs.length < 1) {
                    return;
                }
                const userDetails: DocumentData = userDocs.docs[0].data();
                delete userDetails.password;
                dispatch({
                    type: FIREBASE_STATE_CHANGED,
                    payload: {
                        isLoggedIn: true,
                        user: userDetails as UserAccount,
                    },
                });
            } else {
                dispatch({
                    type: FIREBASE_STATE_CHANGED,
                    payload: {
                        isLoggedIn: false,
                        user: null,
                    },
                });
            }
        },
        [dispatch]
    );

    useEffect(() => {
        const handleStateChange = async () => {
            firebase.auth().onAuthStateChanged(async (user) => {
                handleAuthChange(user);
            });
        };
        handleStateChange();
    }, [dispatch, handleAuthChange]);

    if (!state.isInitialized) {
        return <Loader />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseEmailPasswordNewUser,
                firebaseEmailPasswordSignIn,
                firebaseGoogleSignIn,
                firebaseCheckAccountExists,
                firebaseSendPasswordResetEmail,
                firebaseVerifyPasswordResetCode,
                firebaseResetPassword,
                firebaseChangeCurrentUserEmail,
                // @ts-ignore
                firebaseReAuthenticate,
                // @ts-ignore
                firebaseUpdatePassword,
                // @ts-ignore
                dispatch,
                logout,
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

export default FirebaseContext;
