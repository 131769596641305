import {
    Box,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    ImageListItemProps,
    ImageListProps,
    SxProps,
    useMediaQuery,
} from '@mui/material';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router';

const imageListItem: SxProps = {
    cursor: 'pointer',
    overflow: 'hidden',
    backgroundColor: 'background.default',
    '& .MuiImageListItemBar-title': {
        whiteSpace: 'normal',
    },
    '& img': {
        opacity: 0.7,
        transition: 'opacity 200ms linear',
    },
    '&:hover': {
        '& img': {
            opacity: 1,
        },
    },
};

export type CallToActionConfig = {
    title: string;
    subtitle?: ReactNode;
    src: string;
    to?: string;
    props?: Partial<ImageListItemProps>;
};
type CTAGridProps = Omit<ImageListProps, 'children'> & {
    items: CallToActionConfig[];
};
export const CallToActionGrid: React.FC<CTAGridProps> = (props) => {
    const { items, ...imageListProps } = props;
    const width600 = useMediaQuery('(max-width: 599px)');
    const width800 = useMediaQuery('(max-width: 799px)');

    const navigate = useNavigate();

    return (
        <ImageList
            cols={width600 ? 1 : width800 ? 2 : 4}
            gap={16}
            sx={{ width: '100%', height: 'auto', m: 0, maxWidth: 1200 }}
            {...imageListProps}
        >
            {items.map((item, index) => (
                <ImageListItem
                    key={`card_${index}`}
                    {...item.props}
                    sx={Object.assign({}, imageListItem, (item.props || {}).sx)}
                    onClick={item.to ? () => navigate(item.to!) : item.props.onClick}
                >
                    <Box sx={{ position: 'relative', pb: '100%' }}>
                        <img
                            src={`${item.src}?w=200&h=200&fit=crop&auto=format`}
                            loading="lazy"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                            alt={'A nice-looking house'}
                        />
                    </Box>
                    {item.title || item.subtitle ? (
                        <ImageListItemBar title={item.title} subtitle={item.subtitle} />
                    ) : null}
                </ImageListItem>
            ))}
        </ImageList>
    );
};
