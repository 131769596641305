import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, IconButton, Toolbar } from '@mui/material';

import { AnimatedHamburger } from '../icons/AnimatedHamburger';
import { SerpicoIcon } from '../icons/SerpicoIcon';
import { NavigationDrawer } from './NavigationDrawer';

export const Header: React.FC = (props) => {
    const [isMenu, setIsMenu] = useState(true);
    const [soundPlayed, setSoundPlayed] = useState(false); // To track if sound has played
    const navigate = useNavigate();

    const playSound = () => {
        if (!soundPlayed) {
            const audio = new Audio('/serpico.mp3');
            audio.volume = 0.7;
            audio.play().then(() => {
                setSoundPlayed(true);
                // Reset the soundPlayed state after the audio has finished
                setTimeout(() => setSoundPlayed(false), (audio.duration * 1000));
            });
        }
    };

    // Play the sound when drawer opens
    useEffect(() => {
        if (!isMenu) {
            playSound();
        }
    }, [isMenu]);

    return (
        <>
            <AppBar sx={{ backgroundColor: 'transparent', zIndex: 2000 }} elevation={0}>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <SerpicoIcon
                        sx={{ fontSize: 62, color: 'primary.main', cursor: 'pointer' }}
                        onClick={() => {
                            navigate('/');
                            playSound();  // Play the sound when the SerpicoIcon is clicked
                        }}
                    />
                    <IconButton
                        edge={'end'}
                        size={'large'}
                        sx={{ color: isMenu ? 'primary.main' : 'white' }}
                        onClick={() => setIsMenu((old) => !old)}
                    >
                        <AnimatedHamburger isMenu={isMenu} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <NavigationDrawer open={!isMenu} anchor={'right'} onClose={() => setIsMenu(true)} />
        </>
    );
};
